import styles from "./index.module.scss";
import classNames from "classnames";
import { ModalRoot } from "../Modal";
import { CssButton } from "../Buttons";
import { useState, useEffect } from "react";
import { buildWoofyTokenImage, getWoofyMetadataId } from "../../utils/web3";
import Grid from "./Grid";
import { useContext } from "react";
import { ContractsContext } from "../../providers/ContractsProvider";

const Modal = ({
  title,
  page,
  onClose,
  selectedAmount,
  items,
}: {
  title: string;
  page: string;
  selectedAmount: number;
  onClose: () => void;
  items?: number[];
}) => {
  let modal = (
    <div
      className={styles.modalPage}
      style={page === "confirmation" ? { paddingTop: "10rem" } : {}}
    >
      <div className={styles.modalContents}>
        <div className={styles.card}>
          <div
            className={styles.title}
            style={
              page === "loading"
                ? { backgroundColor: "#317edc" }
                : page === "confirmation"
                ? { backgroundColor: "#bde053" }
                : { backgroundColor: "#d56558" }
            }
          >
            {page === "loading"
              ? title === "campsite"
                ? "LOADING..."
                : "DIG DIG..."
              : page === "confirmation"
              ? "SUCCESS!"
              : "Something went wrong!"}
          </div>
          <div className={styles.content}>
            {page === "confirmation" && (
              <div className={styles.confirmationImg} />
            )}
            <div
              style={{
                fontFamily: "montserrat",
                textAlign: "center",
              }}
            >
              {page === "loading"
                ? `${
                    title === "campsite"
                      ? `Waiting on VRF response to come back for the reveal of ${selectedAmount} ${
                          selectedAmount > 1 ? `Woofys` : `Woofy`
                        }.`
                      : `${selectedAmount} ${
                          selectedAmount > 1
                            ? `Cozy Penguins Explorers are `
                            : `Cozy Penguin Explorer is `
                        }  digging!`
                  } `
                : page === "confirmation" && title === "rescue"
                ? `${
                    items.length > 1
                      ? `${items.length} Woofys capsules are`
                      : `Woofy capsule is`
                  } rescued!`
                : page === "error"
                ? "Oops! Lets try again!"
                : null}
            </div>
          </div>
        </div>

        <div className={classNames(styles.modalButtons)}>
          <CssButton
            onClick={onClose}
            text={
              page === "loading"
                ? title === "campsite"
                  ? "Revealing..."
                  : "Digging..."
                : page === "confirmation"
                ? "Done"
                : "Try Again!"
            }
            rounded={false}
            fontSize="2rem"
            shadowSize="4px"
            color="#42e6e8"
            disabled={page === "loading"}
            className={styles.nowrap}
          />
        </div>
      </div>
    </div>
  );
  const contracts = useContext(ContractsContext);
  const [imageNum, setImageNum] = useState(0);
  const [metadataId, setMetadataId] = useState(0);

  useEffect(() => {
    if (page === "confirmation" && title === "campsite") {
      const woofyMetadataId = async () => {
        setMetadataId(
          await getWoofyMetadataId(contracts.woofy, items[imageNum]),
        );
      };
      woofyMetadataId();
    }
  }, [imageNum, page, title]);

  if (page === "confirmation" && title === "campsite") {
    const imageViewerModal = (
      <div className={styles.modalPage} style={{ paddingTop: "2rem" }}>
        <div className={styles.modalContents}>
          {" "}
          <div className={styles.imageViewerTitle}>
            {items.length} {items.length > 1 ? "Woofys" : "Woofy"} revealed!
          </div>
          <div className={styles.woofyCardWrapper}>
            <div className={styles.woofyCard}>
              <div className={styles.glimmer} />
              <div className={styles.woofyImageUnder} />

              <div className={styles.woofyImage}>
                <img
                  src={
                    metadataId !== 0
                      ? buildWoofyTokenImage(metadataId)
                      : "/assets/images/icons/loading.gif"
                  }
                />
              </div>
              <div className={styles.woofyId}>
                <div>
                  <span style={{ color: "#ffc758", paddingRight: "0.35rem" }}>
                    Woofy #{metadataId}
                  </span>
                  {"  "}
                  emerges
                </div>
                <div>from Capsule #{items[imageNum]}!</div>
              </div>
            </div>
            <div className={styles.selectWrapper}>
              <div className={styles.select}>
                <Grid
                  title="woofys image viewer"
                  balance={items.length}
                  gridItems={items}
                  selected={items.map((woofy) => false)}
                  toggleSelected={(i) => setImageNum(i)}
                  loaded={true}
                />
              </div>
            </div>
          </div>
          <div className={classNames(styles.modalButtons)}>
            <CssButton
              onClick={onClose}
              text="Done"
              rounded={false}
              fontSize="2rem"
              shadowSize="4px"
              color="#42e6e8"
              className={styles.nowrap}
            />
          </div>
        </div>
      </div>
    );
    modal = imageViewerModal;
  }

  return (
    <ModalRoot
      open={page !== ""}
      onClose={onClose}
      transparentblur
      nobg
      closeButton={"none"}
    >
      {page !== "" && modal}
    </ModalRoot>
  );
};
export default Modal;
