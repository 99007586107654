import classNames from "classnames";
import Avatar from "../../../components/Avatars";
import { CssButton } from "../../Buttons";
import styles from "./index.module.scss";

const Bazaar = () => {
  return (
    <div
      className={classNames(styles.cardWrapper)}
      style={{
        paddingTop: "10rem",
      }}
    >
      <img
        className={styles.logo}
        style={{ marginTop: "100px", width: "300px" }}
        src="/assets/images/region/polarpeaks/bazaar/logo_bazaar.png"
      />
      <div className={styles.card}>
        <div className={styles.content}>
          The locals excavated many Woofy capsules and brought them to the
          Bazaar to sell.
        </div>
      </div>
      <div style={{ position: "relative", top: "-50px" }}>
        <CssButton
          onClick={() => {
            window.location.assign(
              "https://avax.hyperspace.xyz/launchpad/5ad14893-3f7e-4be2-9205-d2122591c9f2",
            );
          }}
          text={`Let's Go!`}
          color="#42e6e8"
        />
      </div>
      <Avatar
        img="/assets/images/region/polarpeaks/bazaar/trader.png"
        style={{
          left: "50rem",
          bottom: "5rem",
          width: "700px",
        }}
      />
    </div>
  );
};

export default Bazaar;
