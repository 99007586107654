import styles from "./index.module.scss";

const Banner = ({
  text,
  color,
  fontSize = null,
  shadowSize = null,
}: {
  text: string;
  color: string;
  fontSize?: string | null;
  shadowSize?: string | null;
}) => {
  return (
    <div className={styles.banner}>
      <div
        className={styles.text}
        style={
          {
            "--font-size": fontSize,
            "--shadow-size": shadowSize,
          } as React.CSSProperties
        }
      >
        {text}
      </div>
      <img
        alt="Banner"
        src={
          color === "red"
            ? "/assets/images/banners/panel_red.png"
            : color === "blue"
            ? "/assets/images/banners/panel_blue.png"
            : "/assets/images/banners/panel_tan.png"
        }
        style={{ width: "100%", height: "auto" }}
      />
    </div>
  );
};

export default Banner;
