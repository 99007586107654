import { ConnectButton } from "@rainbow-me/rainbowkit";
import styles from "./index.module.scss";
import { lightTheme, Theme, AvatarComponent } from "@rainbow-me/rainbowkit";
import merge from "lodash.merge";

export const CustomConnectButton = () => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");
        return (
          <div
            className={styles.root}
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button
                    className={styles.button}
                    onClick={openConnectModal}
                    type="button"
                  >
                    Connect Wallet
                  </button>
                );
              }
              if (chain.unsupported) {
                return (
                  <button
                    className={styles.button}
                    onClick={openChainModal}
                    type="button"
                  >
                    Wrong network
                  </button>
                );
              }
              return (
                <div
                  className={styles.root}
                  style={{ display: "flex", gap: 12 }}
                >
                  <button
                    className={styles.button}
                    onClick={openChainModal}
                    style={{ display: "flex", alignItems: "center" }}
                    type="button"
                  >
                    {chain.hasIcon && (
                      <div
                        style={{
                          background: chain.iconBackground,
                          width: 25,
                          height: 25,
                          borderRadius: 999,
                          overflow: "hidden",
                        }}
                      >
                        {chain.iconUrl && (
                          <img
                            alt={chain.name ?? "Chain icon"}
                            src={chain.iconUrl}
                            style={{ width: 25, height: 25 }}
                          />
                        )}
                      </div>
                    )}
                  </button>
                  <button
                    className={styles.button}
                    onClick={openAccountModal}
                    type="button"
                  >
                    {account.displayName}
                  </button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};

export const modalTheme = merge(lightTheme(), {
  colors: {
    accentColor: "#5196e6",
    closeButton: "white",
    closeButtonBackground: "#5196e6",
    modalBackground: "#eeeae7",
  },
  fonts: {
    body: "montserrat",
  },
} as Theme);

export const penguinAvatar: AvatarComponent = () => {
  return (
    <img
      src="/assets/images/region/cozyreef/portal/icon_pending.png"
      width="50px"
      height="50px"
      style={{ borderRadius: 999 }}
    />
  );
};
