import { useEffect, useState, useRef, ReactNode } from "react";
import styles from "./index.module.scss";
import AudioManager, { AudioGroup } from "../AudioManager";
import { VolumeButton } from "../hud";
import { HEIGHT, WIDTH } from "./constants";
import { isMobile } from "react-device-detect";
import classNames from "classnames";

const Map = ({ children }: { children }) => {
  const root = useRef<HTMLDivElement>();
  const map = useRef<HTMLDivElement>();

  // Window Resizing
  useEffect(() => {
    const rescale = () => {
      if (!map.current || !root.current) {
        return;
      }
      const { offsetWidth: width, offsetHeight: height } = map.current;
      const { offsetWidth: rootWidth, offsetHeight: rootHeight } = root.current;

      const expectedHeight = (rootWidth * height) / width;
      const newHeight =
        expectedHeight <= rootHeight ? expectedHeight : rootHeight;
      const scale = newHeight / height;

      map.current.style.transform = `scale(${scale}) translate(-50%)`;
    };
    rescale();
    window.addEventListener("resize", rescale);
    return () => window.removeEventListener("resize", rescale);
  }, [map, root]);

  return (
    <>
      <div className={styles.root} ref={root}>
        <div
          className={styles.map}
          style={
            {
              "--width": WIDTH + "px",
              "--height": HEIGHT + "px",
            } as React.CSSProperties
          }
          ref={map}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default Map;
