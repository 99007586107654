import { createContext, useContext, useEffect, useMemo } from "react";
import { useImmer } from "use-immer";

const TransactionHistoryKey = "cozyverse-transactions";

export type TravelTransactionsContextValue = {
  loaded: boolean;
  messageIds: string[];
  addMessageId: (msgId: string) => void;
  removeMessageId: (msgId: string) => void;
};

const defaultContext: TravelTransactionsContextValue = {
  loaded: false,
  messageIds: [],
  addMessageId: (msgId: string) => {},
  removeMessageId: (msgId: string) => {},
};

export const TravelTransactionsContext =
  createContext<TravelTransactionsContextValue>(defaultContext);

const TravelTransactionsProvider = ({ children }) => {
  const [state, setState] =
    useImmer<TravelTransactionsContextValue>(defaultContext);

  const loadTransactionHistory = (): string[] => {
    const txHistory = window.localStorage.getItem(TransactionHistoryKey);
    return txHistory ? JSON.parse(txHistory) : [];
  };

  const addMessageId = (msgId: string) => {
    const txHistory = [msgId, ...loadTransactionHistory()];
    window.localStorage.setItem(
      TransactionHistoryKey,
      JSON.stringify(txHistory),
    );
  };

  const removeMessageId = (msgId: string) => {
    const txHistory = state.messageIds.filter(
      (_msgId: string) => _msgId !== msgId,
    );
    window.localStorage.setItem(
      TransactionHistoryKey,
      JSON.stringify(txHistory),
    );
    setState((state) => {
      state.messageIds = loadTransactionHistory();
    });
  };

  useEffect(() => {
    setState((state) => {
      state.loaded = true;
      state.messageIds = loadTransactionHistory();
    });
  }, []);

  useEffect(() => {
    setState((state) => {
      state.messageIds = loadTransactionHistory();
    });
  }, [window.localStorage.getItem(TransactionHistoryKey)]);

  return (
    <TravelTransactionsContext.Provider
      value={{
        loaded: state.loaded,
        messageIds: state.messageIds,
        addMessageId: addMessageId,
        removeMessageId: removeMessageId,
      }}
    >
      {children}
    </TravelTransactionsContext.Provider>
  );
};

export default TravelTransactionsProvider;
