import classNames from "classnames";
import Avatar from "../../../components/Avatars";
import { CssButton } from "../../Buttons";
import styles from "./index.module.scss";
import { RegionCozyReefPortal, RegionPolarPeaksPortal } from "constants/links";

const Portal = ({ region }: { region: string }) => {
  return (
    <div
      className={classNames(styles.cardWrapper)}
      style={{
        paddingTop: "3rem",
      }}
    >
      <img
        className={styles.logo}
        style={{ marginTop: "250px" }}
        src="/assets/images/region/cozyreef/logo_portal.png"
      />
      <div className={classNames(styles.card)}>
        <div className={styles.content}>
          {region === "cozyreef"
            ? `Send your Cozy Penguins through the Portal and journey to lands
          unknown.`
            : `Cozy Explorers made it through the portal and are setting up camp.`}
        </div>
      </div>
      <div style={{ position: "relative", top: "-50px" }}>
        <CssButton
          onClick={() =>
            region === "cozyreef"
              ? window.location.assign(RegionCozyReefPortal)
              : window.location.assign(RegionPolarPeaksPortal)
          }
          text={region === "cozyreef" ? `Travel` : `Visit`}
        />
      </div>
      <Avatar
        img="/assets/images/region/cozyreef/portal.png"
        style={{
          left: "45rem",
          bottom: "0rem",
          width: "350px",
        }}
      />
    </div>
  );
};

export default Portal;
