import styles from "./index.module.scss";

const Panel = ({ img, title, subtitle, button }) => {
  const buttonDiv = <div className={styles.button}>{button}</div>;
  return (
    <div className={styles.panel}>
      <div className={styles.content}>
        <h1 className={styles.title}>{title}</h1>
        <h3 className={styles.subtitle}>{subtitle}</h3>
      </div>
      <div className={styles.background}>
        <img src={img} />
      </div>
      {button ? buttonDiv : null}
    </div>
  );
};

export default Panel;
