import classNames from "classnames";
import { ImageButton } from "../../Buttons";
import { CollectionsCozyPenguins } from "constants/links";
import Avatar from "../../../components/Avatars";
import { CssButton } from "../../Buttons";
import styles from "./index.module.scss";
import AudioManager from "../../AudioManager";

const CozyPenguins = () => {
  return (
    <div
      className={classNames(styles.cardWrapper)}
      style={{
        paddingTop: "3rem",
      }}
    >
      <img
        className={styles.logo}
        src="/assets/images/collection/cozypenguins/logo_cozypenguins.png"
      />
      <div className={classNames(styles.card)}>
        <div className={styles.banner}>
          <img src="/assets/images/collection/cozypenguins/cozy_penguins_splash.png" />
        </div>
        <div className={styles.content}>
          Learn more about Cozy Penguins and how they found their way onto the
          Cozy Reef!
        </div>
      </div>
      <div style={{ position: "relative", top: "-50px" }}>
        <CssButton
          onClick={() => {
            window.location.assign(CollectionsCozyPenguins);
          }}
          text="Learn More"
        />
      </div>
      <Avatar
        img="/assets/images/region/cozyreef/cr.png"
        style={{
          left: "45rem",
          bottom: "5rem",
          width: "600px",
        }}
      />
    </div>
  );
};

export default CozyPenguins;
