import CozyPenguinInterface from "../../abis/MockCozyPenguin.sol/MockCozyPenguin.json";
import CozyPenguinTravelerInterface from "../../abis/CozyPenguinTraveler.sol/CozyPenguinTraveler.json";
import CCIPethInterface from "../../abis/CCIPeth.sol/CCIPeth.json";
import CCIPavaxInterface from "../../abis/CCIPavax.sol/CCIPavax.json";
import WoofyInterface from "../../abis/Woofy.sol/Woofy.json";
import { MockCozyPenguin as CozyPenguin } from "../../typechain";
import { CCIPeth, CCIPavax, CozyPenguinTraveler } from "../../typechain";
import { Woofy } from "../../typechain_woofy";
import deployments from "../../utils/web3/deployments";
import { ethers } from "ethers";
import { createContext, useEffect, useMemo } from "react";
import { useImmer } from "use-immer";
import { useAccount, useNetwork } from "wagmi";

export type ContractsContextValue = {
  loaded: boolean;
  cozyPenguin: CozyPenguin;
  cozyPenguinTraveler: CozyPenguinTraveler;
  ccipEth: CCIPeth;
  ccipAvax: CCIPavax;
  woofy: Woofy;
};

const defaultContext: ContractsContextValue = {
  loaded: false,
  cozyPenguin: null,
  cozyPenguinTraveler: null,
  ccipEth: null,
  ccipAvax: null,
  woofy: null,
};

export const ContractsContext =
  createContext<ContractsContextValue>(defaultContext);

const ContractsProvider = ({ children }) => {
  const { isConnected, address } = useAccount();
  const { chain } = useNetwork();

  const id = useMemo(() => ({}), [address, chain?.id]);

  const [state, setState] = useImmer<{
    id: any;
    contracts: ContractsContextValue;
  }>({
    id: null,
    contracts: defaultContext,
  });

  useEffect(() => {
    const setup = async () => {
      const { ethereum } = window;
      const provider = new ethers.BrowserProvider(ethereum);
      const signer = await provider.getSigner();
      const cozyPenguin = new ethers.Contract(
        deployments.contracts.CozyPenguin.address,
        CozyPenguinInterface.abi,
        signer,
      ) as unknown as CozyPenguin;

      const cozyPenguinTraveler = new ethers.Contract(
        deployments.contracts.CozyPenguinTraveler.address,
        CozyPenguinTravelerInterface.abi,
        signer,
      ) as unknown as CozyPenguinTraveler;

      const ccipEth = new ethers.Contract(
        deployments.contracts.CCIPeth.address,
        CCIPethInterface.abi,
        signer,
      ) as unknown as CCIPeth;

      const ccipAvax = new ethers.Contract(
        deployments.contracts.CCIPavax.address,
        CCIPavaxInterface.abi,
        signer,
      ) as unknown as CCIPavax;

      const woofy = new ethers.Contract(
        deployments.contracts.Woofy.address,
        WoofyInterface.abi,
        signer,
      ) as unknown as Woofy;

      setState({
        id: id,
        contracts: {
          loaded: true,
          cozyPenguin: cozyPenguin,
          cozyPenguinTraveler: cozyPenguinTraveler,
          ccipEth: ccipEth,
          ccipAvax: ccipAvax,
          woofy: woofy,
        },
      });
    };

    if (isConnected) {
      setup();
      return () => {
        setState(() => ({
          id: null,
          ...defaultContext,
        }));
      };
    }
  }, [isConnected, chain, address]);

  const contracts = id === state.id ? state.contracts : defaultContext;

  return (
    <ContractsContext.Provider value={contracts}>
      {children}
    </ContractsContext.Provider>
  );
};

export default ContractsProvider;
