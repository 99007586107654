import classNames from "classnames";
import Avatar from "../../../components/Avatars";
import { CssButton } from "../../Buttons";
import styles from "./index.module.scss";

const CozyLabs = ({ setOpen }: { setOpen: (boolean) => void }) => {
  return (
    <div
      className={classNames(styles.cardWrapper)}
      style={{
        paddingTop: "10rem",
      }}
    >
      <img
        className={styles.logo}
        src="/assets/images/region/cozyreef/logo_cozylabs.png"
      />
      <div className={classNames(styles.card)}>
        <div
          className={styles.content}
          style={{
            fontSize: "2rem",
          }}
        >
          See what Cozy and friends have been cooking up at the lab.
        </div>
      </div>
      <div style={{ position: "relative", top: "-50px" }}>
        <CssButton
          onClick={() => {
            setOpen(true);
          }}
          text="Read now"
        />
      </div>
      <Avatar
        img="/assets/images/region/cozyreef/cards/cozylabs/avatar.png"
        style={{
          left: "40rem",
          bottom: "-20rem",
          width: "750px",
        }}
      />
    </div>
  );
};

export default CozyLabs;
