import styles from "./index.module.scss";
import classNames from "classnames";
import Avatar from "../../../../components/Avatars";
import deployments from "../../../../utils/web3/deployments";
import { useAccount, useNetwork } from "wagmi";
import { useConnectModal, useChainModal } from "@rainbow-me/rainbowkit";
import { CssButton } from "../../../../components/Buttons";
import { useState, useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { ContractsContext } from "../../../../providers/ContractsProvider";
import { fetchNFTs, getNftBalance } from "../../../../utils/web3";
import { withPromiseToast } from "../../../../utils";
import CapsulesGrid from "components/Web3/PolarPeaks/Campsite/CapsulesGrid";
import Grid from "components/Web3/Grid";
import Modal from "components/Web3/Modal";
import LoadingModal from "components/Web3/LoadingModal";
import TabWrapper from "../../../../components/Web3/PolarPeaks/TabWrapper";
import Indicator from "../../../../components/Web3/PolarPeaks/Indicator";
import StatusChecker from "../../../../components/Web3/PolarPeaks/StatusChecker";
import { RegionPolarPeaksRescue } from "../../../../constants/links";
import { getWoofyMetadataId } from "../../../../utils/web3";
import WoofyTraitsModal from "../../../../components/WoofyTraitsModal";

const snowflakes = [];
for (let i = 0; i < 50; i++) {
  snowflakes.push(<div key={i} className={styles.snowflake}></div>);
}
const confettiColors = ["#ff4e00", "#007bff", "#33cabb", "#ffcd00", "#e83e8c"];
const confettiElements = [];
for (let i = 0; i < 200; i++) {
  confettiElements.push(
    <div
      key={i}
      className={styles.confetti}
      style={{
        background:
          confettiColors[Math.floor(Math.random() * confettiColors.length)],
        animationDuration: `${3 + Math.random()}s`,
        animationDelay: `-${Math.random() * 2}s`,
      }}
    ></div>,
  );
}

const Campsite = () => {
  const contracts = useContext(ContractsContext);
  const { isConnected, address } = useAccount();
  const { openChainModal } = useChainModal();
  const { openConnectModal } = useConnectModal();
  const { chain } = useNetwork();
  const [tab, setTab] = useState(0);
  const revealLimit = 10;
  const [selectedCapsulesAmount, setSelectedCapsulesAmount] = useState(0);
  const [state, setState] = useImmer<{
    loadedAccount: string | null;
    loaded: boolean;
    woofyBalance: number;
    woofys: number[];
    totalWoofys: number;
    selectedCapsules: boolean[];
    capsules: number[];
    revealedWoofys: number[];
    page: string;
    currentlyRevealedWoofys: number[];
    revealLock: boolean;
    loadingModal: boolean;
    focusedWoofyId: number;
  }>({
    loadedAccount: null,
    loaded: false,
    woofyBalance: 0,
    woofys: [],
    totalWoofys: 0,
    selectedCapsules: [],
    capsules: [],
    revealedWoofys: [],
    page: "",
    currentlyRevealedWoofys: [],
    revealLock: true,
    loadingModal: false,
    focusedWoofyId: -1,
  });

  const loadNFTs = async () => {
    setState((state) => {
      state.loadingModal = true;
    });
    const woofys = await fetchNFTs(address, contracts.woofy);

    const revealedWoofys = [];
    const capsules = [];
    const getRevealedWoofys = async (woofy): Promise<boolean> => {
      return await contracts.woofy.isTokenRevealed(woofy);
    };

    for (let i = 0; i < woofys.length; i++) {
      const isWoofyRevealed = await getRevealedWoofys(woofys[i]);
      if (isWoofyRevealed) {
        revealedWoofys.push(woofys[i]);
      } else {
        capsules.push(woofys[i]);
      }
    }

    setState((state) => {
      state.woofyBalance = woofys.length;
      state.woofys = woofys;
      state.capsules = capsules;
      state.revealedWoofys = revealedWoofys;
      state.selectedCapsules = capsules.map(() => false);
    });
    setState((state) => {
      state.loadingModal = false;
    });
  };

  useEffect(() => {
    const initialize = async () => {
      const woofyBalance = parseInt(
        (await getNftBalance(address, contracts.woofy)).toString(),
      );
      const getTotalWoofys = async (): Promise<bigint> => {
        return await contracts.woofy.totalTokenSupply();
      };
      const totalWoofys = await getTotalWoofys();

      const getRevealLock = async () => {
        return await contracts.woofy.revealLock();
      };
      const revealLock = await getRevealLock();

      setState((state) => {
        state.loaded = true;
        state.totalWoofys = Number(totalWoofys);
        state.loadedAccount = address;
        state.revealLock = revealLock;
      });
      if (woofyBalance > 0) {
        loadNFTs();
      }
    };

    if (contracts.loaded && isConnected) {
      if (
        (chain?.id === 43114 && deployments.environmentName === "production") ||
        (chain?.id === 43113 && deployments.environmentName === "staging")
      ) {
        initialize();
      }
    }
  }, [contracts.loaded, address, chain, isConnected, state.woofyBalance]);

  useEffect(() => {
    setState((state) => {
      state.loaded = false;
      state.loadedAccount = "";
      state.woofys = [];
    });
  }, [address]);

  useEffect(() => {
    setSelectedCapsulesAmount(
      state.capsules.filter((_, i) => state.selectedCapsules[i]).length,
    );
  }, [state.selectedCapsules]);

  const isRightChain = () => {
    if (
      (chain?.id === 43114 && deployments.environmentName === "production") ||
      (chain?.id === 43113 && deployments.environmentName === "staging")
    ) {
      return true;
    }
    return false;
  };

  const revealWoofys = async () => {
    const result = await withPromiseToast(async () => {
      setState((state) => {
        state.page = "loading";
      });
      const selectedTokenIds = state.capsules.filter(
        (_, i) => state.selectedCapsules[i],
      );
      const revealTx = await contracts.woofy.reveal(selectedTokenIds, {
        gasLimit: 2500000,
      });
      for (let i = 0; i < 10; i++) {
        await revealTx.wait(i);
        let metadataId = await getWoofyMetadataId(
          contracts.woofy,
          selectedTokenIds[0],
        );
        if (metadataId !== 0) break;
      }
    }).finally(() => {
      setState((state) => {
        state.currentlyRevealedWoofys = state.capsules.filter(
          (_, i) => state.selectedCapsules[i],
        );
        loadNFTs();
        state.page = "confirmation";
      });
    });
    if (result === "Something bad happened") {
      setState((state) => {
        state.page = "error";
      });
    }
  };

  const connectWallet = (
    <>
      <Avatar
        img="/assets/images/region/polarpeaks/campsite/campsite_big.png"
        className={styles.campsite}
      />
      <CssButton
        onClick={
          !isConnected
            ? openConnectModal
            : !isRightChain()
            ? openChainModal
            : null
        }
        text={
          !isConnected
            ? `Connect Wallet`
            : !isRightChain()
            ? `Change Chain`
            : null
        }
        rounded={false}
        fontSize="2rem"
        color="#42e6e8"
        className={styles.button}
        shadowSize="4px"
      />
    </>
  );

  const tabs = (
    <div className={styles.signsGrid}>
      <div
        className={classNames(styles.imageSign, styles.capsules)}
        onClick={() => setTab(1)}
      >
        <div
          className={styles.text}
          style={{ right: "2%", transform: "translateY(25%)" }}
        >
          Open my capsules
        </div>
      </div>
      <div
        className={classNames(styles.imageSign, styles.woofys)}
        onClick={() => setTab(2)}
      >
        <div className={styles.text}>See my woofys</div>
      </div>
      <div
        className={classNames(styles.imageSign, styles.checker)}
        onClick={() => setTab(3)}
      >
        <div
          className={styles.text}
          style={{ right: "2%", transform: "translateY(-5%)" }}
        >
          Search woofys
        </div>
      </div>
    </div>
  );

  const loading = (
    <div className={styles.noItemsWrapper}>
      <div className={styles.noItemsText}>
        <div className={styles.bold} style={{ color: "#42e6e8" }}>
          LOADING
        </div>
        <div className={styles.loadingDots}>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
        </div>
      </div>
    </div>
  );

  const capsules =
    !state.loaded || state.loadingModal ? (
      loading
    ) : state.capsules.length > 0 ? (
      <>
        <CapsulesGrid
          balance={state.capsules.length}
          capsules={state.capsules}
          selected={state.selectedCapsules}
          toggleSelected={(i) => {
            if (!state.revealLock) {
              selectedCapsulesAmount < revealLimit
                ? setState((state) => {
                    state.selectedCapsules[i] = !state.selectedCapsules[i];
                  })
                : setState((state) => {
                    state.selectedCapsules[i] = false;
                  });
            }
          }}
          loaded={state.loaded}
        />
        <CssButton
          onClick={revealWoofys}
          text={state.revealLock ? "not open yet" : "open!"}
          rounded={false}
          fontSize="1rem"
          color="#42e6e8"
          className={state.revealLock ? styles.smallButton : styles.button}
          disabled={selectedCapsulesAmount === 0 || state.revealLock}
          shadowSize={state.revealLock ? "3px" : "4px"}
        />
      </>
    ) : (
      <div className={styles.noItemsWrapper}>
        <div className={styles.noItemsText}>
          You don't have any Capsules.{" "}
          <a
            href={RegionPolarPeaksRescue}
            className={styles.bold}
            style={{ color: "#ffc758" }}
          >
            rescue
          </a>{" "}
          one with your Cozy Explorers or purchase one from the{" "}
          <a className={styles.bold} style={{ color: "#42e6e8" }}>
            bazzar
          </a>{" "}
        </div>
      </div>
    );

  const woofys =
    !state.loaded || state.loadingModal ? (
      loading
    ) : state.revealedWoofys.length > 0 ? (
      <div className={styles.selectWrapper}>
        <div className={styles.select}>
          <Grid
            title="woofys"
            balance={state.revealedWoofys.length}
            gridItems={state.revealedWoofys}
            selected={state.revealedWoofys.map((woofy) => false)}
            toggleSelected={(i) => {
              setState((state) => {
                if (state.focusedWoofyId !== -1) {
                  state.focusedWoofyId = -1;
                } else {
                  state.focusedWoofyId = state.revealedWoofys[i];
                }
              });
            }}
            loaded={state.loaded}
          />
        </div>
      </div>
    ) : (
      <div className={styles.noItemsWrapper}>
        <div className={styles.noItemsText}>
          You don't have any Woofys. Adopt one{" "}
          <a className={styles.bold} style={{ color: "#ffc758" }}>
            here
          </a>{" "}
        </div>
      </div>
    );

  const checker = <StatusChecker title="woofys" />;

  const content =
    !isConnected || !isRightChain() ? (
      connectWallet
    ) : !tab ? (
      tabs
    ) : (
      <TabWrapper
        title="campsite"
        children={tab === 1 ? capsules : tab === 2 ? woofys : checker}
        tab={tab}
        setTab={setTab}
      />
    );

  return (
    <>
      <div className={styles.root}>
        <div className={styles.contents}>
          <div></div>
          <div className={styles.centerContent}>
            {content !== connectWallet && (
              <Avatar
                img="/assets/images/region/polarpeaks/campsite/campsite_small.png"
                className={styles.logo}
              />
            )}
            {content}
          </div>
          <div
            className={styles.rightContent}
            style={
              !isConnected || !isRightChain()
                ? { gridTemplateColumns: "none" }
                : null
            }
          >
            <div
              className={classNames(styles.textSign)}
              style={
                !isConnected || !isRightChain() ? { alignSelf: "start" } : null
              }
            >
              <img
                className={classNames(styles.sign)}
                src="/assets/images/region/polarpeaks/rescue/panel_text.png"
              />
              <div className={styles.text}>
                The Cozy Penguins brought the capsules to their campsite.
              </div>
            </div>
            {content !== connectWallet && (
              <div className={styles.infoWrapper}>
                <Indicator
                  title={tab === 1 ? "selected" : "woofys"}
                  selectedAmount={selectedCapsulesAmount}
                  selectedLimit={revealLimit}
                  totalWoofys={state.totalWoofys}
                  maxWoofys={5555}
                  myWoofies={state.woofyBalance}
                />
                <Indicator title="my woofys" myWoofies={state.woofyBalance} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.bg}> </div>
        <Avatar
          img="/assets/images/region/polarpeaks/campsite/snow_b.png"
          className={styles.snowbehind}
        />
        <Avatar
          img="/assets/images/region/polarpeaks/campsite/snow_f.png"
          className={styles.snowfront}
        />
        <Avatar
          img="/assets/images/region/polarpeaks/rescue/left.png"
          className={styles.left}
        />
        <Avatar
          img="/assets/images/region/polarpeaks/campsite/r.png"
          className={styles.right}
        />
        <Avatar
          img="/assets/images/region/polarpeaks/campsite/r2.png"
          className={styles.rightCapsule}
        />
        {snowflakes}
      </div>
      <Modal
        title="campsite"
        page={state.page}
        selectedAmount={selectedCapsulesAmount}
        onClose={() => {
          setState((state) => {
            state.page = "";
            state.currentlyRevealedWoofys = [];
          });
        }}
        items={state.currentlyRevealedWoofys}
      />
      <WoofyTraitsModal
        woofyId={state.focusedWoofyId}
        open={state.focusedWoofyId !== -1}
        onClose={() => {
          setState((state) => {
            state.focusedWoofyId = -1;
          });
        }}
      />
      {state.page === "confirmation" && confettiElements}
      {state.page === "" ? null : (
        <Avatar
          img="/assets/images/region/polarpeaks/campsite/snow_f.png"
          className={classNames(styles.modalSnow)}
        />
      )}
    </>
  );
};

export default Campsite;
