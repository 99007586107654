import classNames from "classnames";
import { ReactNode } from "react";
import AudioManager from "../AudioManager";

import styles from "./index.module.scss";

export const Button = ({
  className,
  onMouseDown,
  children,
  circle = false,
  flat = false,
  disabled = false,
}: {
  className?: string;
  onMouseDown: (e: MouseEvent) => void;
  children: ReactNode;
  circle?: boolean;
  flat?: boolean;
  disabled?: boolean;
}) => (
  <div
    className={classNames(className, styles.button, {
      [styles.circle]: circle,
      [styles.flat]: flat,
      [styles.disabled]: disabled,
    })}
    onClick={disabled ? null : AudioManager.instance.onClick(onMouseDown)}
  >
    {children}
  </div>
);

export const ImageButton = ({
  img,
  onMouseDown,
  text,
  className,
  disabled = false,
  bg = false,
}: {
  img: string;
  onMouseDown: (e: MouseEvent) => void;
  className?: string;
  disabled?: boolean;
  text?: string;
  bg?: boolean;
}) => (
  <div
    className={classNames(className, styles.button, styles.image, {
      [styles.disabled]: disabled,
      [styles.text]: text,
      [styles.bg]: bg,
    })}
    onClick={disabled ? null : AudioManager.instance.onClick(onMouseDown)}
  >
    {text ? <div className={styles.content}>{text}</div> : null}
    <img src={img} />
  </div>
);

export const CssButton = ({
  onClick,
  text,
  disabled = false,
  smallMobile = false,
  color,
  rounded = true,
  fontSize = null,
  shadowSize = null,
  className = null,
}: {
  onClick;
  text: string;
  disabled?: boolean;
  smallMobile?: boolean;
  color?: string;
  rounded?: boolean;
  fontSize?: string | null;
  shadowSize?: string | null;
  className?: string;
}) => (
  <div
    className={classNames(styles.btnDonJoseBlack, className, {
      [styles.disabled]: disabled,
      [styles.color]: color,
      [styles.rounded]: rounded,
    })}
    style={
      {
        "--custom-color": color,
        "--font-size": fontSize,
        "--shadow-size": shadowSize,
      } as React.CSSProperties
    }
    onClick={disabled ? null : AudioManager.instance.onClick(onClick)}
  >
    <div className={classNames({ [styles.smallMobile]: smallMobile })}>
      {text}
    </div>
  </div>
);
