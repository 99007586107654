import classNames from "classnames";
import Avatar from "../../../components/Avatars";
import { CssButton } from "../../Buttons";
import styles from "./index.module.scss";
import { RegionPolarPeaksRescue } from "constants/links";

const Rescue = () => {
  return (
    <div
      className={classNames(styles.cardWrapper)}
      style={{
        paddingTop: "3rem",
      }}
    >
      <img
        className={styles.logo}
        style={{ marginTop: "230px", width: "300px" }}
        src="/assets/images/region/polarpeaks/logo_rescue.png"
      />
      <div className={styles.card}>
        <div className={styles.content}>
          The Woofys are trapped under the ice. Take your Cozy Penguins there to
          dig them out!
        </div>
      </div>
      <div style={{ position: "relative", top: "-50px" }}>
        <CssButton
          onClick={() => {
            window.location.assign(RegionPolarPeaksRescue);
          }}
          text={`Let's Go`}
        />
      </div>
      <Avatar
        img="/assets/images/region/polarpeaks/rescue.png"
        style={{
          left: "45rem",
          bottom: "0rem",
          width: "500px",
        }}
      />
    </div>
  );
};

export default Rescue;
