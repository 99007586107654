import classNames from "classnames";
import styles from "./index.module.scss";
import {
  CollectionsCozyPenguins,
  RegionPolarPeaksPortal,
  RegionPolarPeaksRescue,
  RegionPolarPeaksCampsite,
} from "constants/links";
import { CssButton } from "../Buttons";

const MobilePolarPeaksRegion = ({ logoSrc }: { logoSrc: string }) => {
  return (
    <div className={styles.mobileContainer}>
      <div className={styles.topLogo}>
        <img
          className={styles.logo}
          src="/assets/images/region/polarpeaks_mobile/panel_na.png"
        />
      </div>

      <div className={styles.mobileSlideContainer}>
        <img
          className={styles.slideImage}
          src="/assets/images/region/polarpeaks_mobile/portal2.png"
        />
        <div className={classNames(styles.mobileSlideRightInfo)}>
          <div className={styles.mobileSlideLogoContainer}>
            <img
              className={styles.mobileLogo}
              src="/assets/images/region/polarpeaks_mobile/logo_portal.png"
            />
          </div>
          <div className={styles.mobileSlideButtonsContainer}>
            <CssButton
              className={styles.enterPortalButton}
              onClick={() => {
                window.location.assign(RegionPolarPeaksPortal);
              }}
              text="Enter"
            />
          </div>
        </div>
      </div>

      <div className={styles.mobileSlideContainer}>
        <img
          className={styles.slideImage}
          src="/assets/images/region/polarpeaks_mobile/explorer.png"
        />
        <div
          className={classNames(
            styles.mobileSlideLeftInfo,
            styles.cozyExplorersInfo,
          )}
        >
          <div className={styles.mobileSlideLogoContainer}>
            <img
              className={styles.mobileLogo}
              src="/assets/images/region/polarpeaks_mobile/logo_explorers.png"
            />
          </div>
          <div className={styles.mobileSlideButtonsContainer}>
            <CssButton
              className={styles.enterPortalButton}
              onClick={() => {
                window.location.assign(CollectionsCozyPenguins);
              }}
              text="Learn more"
            />
          </div>
        </div>
      </div>

      <div className={styles.mobileSlideContainer}>
        <img
          className={styles.slideImage}
          src="/assets/images/region/polarpeaks_mobile/capsule.png"
        />
        <div className={classNames(styles.mobileSlideRightInfo)}>
          <div className={styles.mobileSlideLogoContainer}>
            <img
              style={{ width: "125px" }}
              src="/assets/images/region/polarpeaks/logo_rescue.png"
            />
          </div>
          <div className={styles.mobileSlideButtonsContainer}>
            <CssButton
              className={styles.enterPortalButton}
              onClick={() => {
                window.location.assign(RegionPolarPeaksRescue);
              }}
              text="Let's go"
            />
          </div>
        </div>
      </div>
      <div className={styles.mobileSlideContainer}>
        <img
          className={styles.slideImage}
          src="/assets/images/region/polarpeaks_mobile/campsite.png"
        />
        <div
          className={classNames(
            styles.mobileSlideLeftInfo,
            styles.campsiteInfo,
          )}
        >
          <div className={styles.mobileSlideLogoContainer}>
            <img
              className={styles.mobileLogo}
              src="/assets/images/region/polarpeaks_mobile/campsite_logo.png"
            />
          </div>
          <div className={styles.mobileSlideButtonsContainer}>
            <CssButton
              className={styles.enterPortalButton}
              onClick={() => {
                window.location.assign(RegionPolarPeaksCampsite);
              }}
              text="Enter"
            />
          </div>
        </div>
      </div>
      <div className={styles.mobileSlideContainer}>
        <img
          className={styles.slideImage}
          src="/assets/images/region/polarpeaks_mobile/bazaar_mobile.png"
        />
        <div className={classNames(styles.mobileSlideRightInfo)}>
          <div className={styles.mobileSlideLogoContainer}>
            <img
              style={{ width: "125px" }}
              src="/assets/images/region/polarpeaks_mobile/logo_bazaar_mobile.png"
            />
          </div>
          <div className={styles.mobileSlideButtonsContainer}>
            <CssButton
              className={styles.enterPortalButton}
              onClick={() => {
                window.location.assign(
                  "https://avax.hyperspace.xyz/launchpad/5ad14893-3f7e-4be2-9205-d2122591c9f2",
                );
              }}
              text="Let's go"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobilePolarPeaksRegion;
