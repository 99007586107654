import Section from "../../../components/Section";
import Avatar from "../../../components/Avatars";
import {
  AppStoreButton,
  AppStoreInfo,
  AppStoreType,
  Description,
  Game,
  Preview,
  Thumbnails,
} from "../../../components/GamePage";

import styles from "./index.module.scss";
import { GamesWaddleWars } from "../../../constants/links";
import { WaddleWarsPreview } from "../WaddleWars";
import { ReactNode } from "react";
import { Helmet } from "react-helmet";

const Subtitle = "An endless Cozy skating adventure";

const Buttons = [
  <AppStoreButton
    className={styles.appStoreMobileButtons}
    href="https://apps.apple.com/us/app/landslide-by-cozy-labs/id6450430184"
    appStore={AppStoreType.apple}
  />,
  <AppStoreButton
    className={styles.appStoreMobileButtons}
    href="https://play.google.com/store/apps/details?id=xyz.cozylabs.landslide"
    appStore={AppStoreType.android}
  />,
];

export const LandslidePreview = ({}: {}) => {
  return (
    <Preview
      titleSrc={"/assets/images/games/land_slide/logo_land_slide.png"}
      cardSrc={"/assets/images/games/land_slide/bg.png"}
      subtitle={Subtitle}
      href={GamesWaddleWars}
    />
  );
};

const LandslideGame = ({ buttons }: { buttons: ReactNode[] }) => {
  const unityConfig = {
    loaderUrl: "/assets/build/landslide/WebGL.loader.js",
    dataUrl: "/assets/build/landslide/WebGL.data",
    frameworkUrl: "/assets/build/landslide/WebGL.framework.js",
    codeUrl: "/assets/build/landslide/WebGL.wasm",
  };

  return (
    <Game
      unityConfig={unityConfig}
      titleSrc={"/assets/images/games/land_slide/logo_land_slide.png"}
      subtitle={Subtitle}
      buttons={buttons}
    />
  );
};

const LandslideHero = ({
  children,
  playable = true,
}: {
  children?;
  playable?: boolean;
}) => {
  return (
    <Section className={styles.hero}>
      <Helmet>
        <title>Cozyverse | Play Landslide</title>
        <meta name="apple-itunes-app" content="app-id=6450430184" />
      </Helmet>
      {playable ? <LandslideGame buttons={Buttons} /> : <LandslidePreview />}
      {children}
      <Avatar
        img="/assets/images/games/land_slide/ls_lfloor.png"
        hideMobile
        style={{
          bottom: "-0.5rem",
          width: "101%",
        }}
      />
      <Avatar
        img="/assets/images/games/land_slide/ls_l.png"
        hideMobile
        style={{
          bottom: "1rem",
          left: "2rem",
          maxWidth: "15%",
        }}
      />
      <Avatar
        img="/assets/images/games/land_slide/ls_r.png"
        hideMobile
        style={{
          bottom: "-1.5rem",
          right: "2rem",
          maxWidth: "70%",
        }}
      />
    </Section>
  );
};

const LandslidePage = () => {
  return (
    <div className={styles.root}>
      <LandslideHero playable={true} />
      <Section className={styles.about}>
        <AppStoreInfo
          iconImg="/assets/images/games/land_slide/icon_landslide.png"
          buttons={[
            <AppStoreButton
              className={styles.appStoreDesktopButtons}
              href="https://apps.apple.com/us/app/landslide-by-cozy-labs/id6450430184"
              appStore={AppStoreType.apple}
            />,
            <AppStoreButton
              className={styles.appStoreDesktopButtons}
              href="https://play.google.com/store/apps/details?id=xyz.cozylabs.landslide"
              appStore={AppStoreType.android}
            />,
          ]}
        />
        <Description>
          <p>
            Landslide takes you on an exhilarating adventure as Cozy and friends
            skateboard down the slopes of an erupting volcano! Strap on your
            skateboard, gather your courage, and prepare for a thrilling endless
            skater experience like no other.
          </p>
          <p>
            Dodge obstacles, navigate treacherous paths, and collect shimmering
            coins as you descend through the volcanic terrain. Watch out for
            massive rocks that stand in your way – unleash epic power-ups to
            clear your path! Execute daring tricks and grind on rails to
            showcase your skills and boost your score to new heights.
          </p>
          <p>
            Challenge yourself to beat your own high-score and unlock a cast of
            adorable new skaters along the way. Will you master the art of
            volcanic skating and become the ultimate Landslide champion?
          </p>
          <h3>How to Play</h3>
          <p>
            Skate until your lives run out. Collect coins, dash through or jump
            on rocks, and collect powerups to gain more points and boosts. As
            you travel further down the volcano you will get faster, so react
            quickly! Complete quests to unlock new skaters.
          </p>
          <p>
            *Mobile browser not supported. Download the app on Apple App Store
            and Google Play.
          </p>
          <h3>Patch Notes (v1.1.0)</h3>
          <h4>Features</h4>
          <ul>
            <li>
              Compete against the world on the global hi-score. Leaderboard
              resets weekly.
            </li>
            <li>
              Collect badges during your endless adventures and complete your
              badge book!
            </li>
            <li>
              Track your lifetime stats. Access this feature on the leaderboard
              from the main menu.
            </li>
            <li>Take a detailed look at your stats on the results screen.</li>
            <li>New game tip system to help you master your skate.</li>
          </ul>
          <h4>Tweaks</h4>
          <ul>
            <li>Hit box to jump smash rocks are more generous.</li>
            <li>
              Added a second indicator for players to double jump and double
              dash. (Mobile only)
            </li>
            <li>
              Moved player slightly to the right to give more clearance from the
              edge of the screen.
            </li>
            <li>
              Reduce variance of platform height for better predictability.
            </li>
          </ul>
        </Description>
        <Thumbnails>
          <img src="/assets/images/games/land_slide/1.png" />
          <img src="/assets/images/games/land_slide/2.png" />
          <img src="/assets/images/games/land_slide/3.png" />
          <img src="/assets/images/games/land_slide/4.png" />
        </Thumbnails>
        <Avatar
          img="/assets/images/games/shared/ducks.png"
          hideMobile
          style={{
            left: "-15rem",
            bottom: "-10rem",
          }}
        />
        <Avatar
          img="/assets/images/games/shared/ducks.png"
          hideMobile
          style={{
            right: "-15rem",
            top: "-10rem",
          }}
        />
      </Section>
    </div>
  );
};

export { LandslidePage, LandslideHero };
