import styles from "./index.module.scss";

const Indicator = ({
  title,
  selectedAmount,
  selectedLimit,
  totalWoofys,
  maxWoofys,
  myWoofies,
}: {
  title: string;
  selectedAmount?: number;
  selectedLimit?: number;
  totalWoofys?: number;
  maxWoofys?: number;
  myWoofies?: number;
}) => {
  const content =
    title === "selected" ? (
      <div
        style={selectedAmount === selectedLimit ? { color: "#f86459" } : null}
      >
        {selectedAmount}/
        <div
          style={{
            display: "inline-block",
            color: selectedAmount === selectedLimit ? "#f86459" : "#4095fd",
          }}
        >
          {selectedLimit}
        </div>
      </div>
    ) : title === "woofys rescued" || title === "woofys" ? (
      <>
        {totalWoofys}/
        <div style={{ display: "inline-block", color: "#4095fd" }}>
          {maxWoofys}
        </div>
      </>
    ) : (
      `${myWoofies}`
    );
  return (
    <>
      <div className={styles.indicatorTitle}>{title}</div>
      <div className={styles.indicatorContent}>{content}</div>
    </>
  );
};

export default Indicator;
