import styles from "./index.module.scss";
import Portal from "components/Web3/Portal";
import Avatar from "../../../../components/Avatars";

const snowflakes = [];
for (let i = 0; i < 50; i++) {
  snowflakes.push(<div key={i} className={styles.snowflake}></div>);
}

const PolarPeaksPortal = () => {
  return (
    <div className={styles.root}>
      <div className={styles.contents}>
        <Portal region="polarpeaks" />
      </div>
      <div className={styles.bg}> </div>
      <Avatar
        img="/assets/images/region/polarpeaks/portal/snow_b.png"
        className={styles.snowbehind}
      />
      <Avatar
        img="/assets/images/region/polarpeaks/portal/snow_f.png"
        className={styles.snowfront}
      />
      <Avatar
        img="/assets/images/region/polarpeaks/portal/pebble1.png"
        className={styles.rock1}
      />
      <Avatar
        img="/assets/images/region/polarpeaks/portal/pebble2.png"
        className={styles.rock2}
      />
      <Avatar
        img="/assets/images/region/polarpeaks/portal/pebble3.png"
        className={styles.rock3}
      />
      <Avatar
        img="/assets/images/region/polarpeaks/portal/pebble4.png"
        className={styles.rock4}
      />
      <Avatar
        img="/assets/images/region/polarpeaks/portal/pebble3.png"
        className={styles.rock5}
      />
      <Avatar
        img="/assets/images/region/polarpeaks/portal/pebble1.png"
        className={styles.rock6}
      />
      {snowflakes}
    </div>
  );
};

export default PolarPeaksPortal;
