import styles from "./index.module.scss";
import { CssButton } from "../../../components/Buttons";
import Avatar from "../../../components/Avatars";
import { useRef, useState } from "react";
import {
  RegionPolarPeaksRescue,
  RegionPolarPeaksPortal,
} from "../../../constants/links";
import Section from "../../../components/Section";
import classNames from "classnames";
import { Bold } from "../../../components/Card";
import { blockScroll } from "../../../components/Scroll";
import { ComicStoryChapter3 } from "../../../components/Comics/Stories";

const snowflakes = [];
for (let i = 0; i < 50; i++) {
  snowflakes.push(<div key={i} className={styles.snowflake}></div>);
}

const woofyNames = [
  "ace",
  "ziggy",
  "scout",
  "ollie",
  "bolt",
  "echo",
  "dottie",
  "coco",
];

const about = {
  ziggy: (
    <>
      Ziggy is defined by her <Bold color="#5592fa">power</Bold> and{" "}
      <Bold color="#5592fa">loyalty</Bold>. Competitive, idealistic, and
      dialed-in, Ziggy turns heads among the Woofys. Ziggy's pack occasionally
      adopts a god-like mentality, demanding more than their fair share when
      playing with others.
    </>
  ),
  ollie: (
    <>
      Ollie is defined by his <Bold color="#fbc150">creativity</Bold> and{" "}
      <Bold color="#fbc150">spunk</Bold>. Funny, spontaneous, and energetic,
      Ollie is an idea machine that won over Woofys through sheer enthusiasm.
    </>
  ),
  ace: (
    <>
      Ace is defined by his <Bold color="#fbc150">charisma</Bold> and{" "}
      <Bold color="#fbc150">wit</Bold>. Relatable, sincere, and warm, Ace is
      just enough of a showman to attract avid followers. The popular kid in
      class that you just can’t hate, no one beats Ace's ability to make
      friends.
    </>
  ),
  scout: (
    <>
      Scout is defined by his <Bold color="#817e7c">strength</Bold> and{" "}
      <Bold color="#817e7c">grit</Bold>. Stoic, impenetrable, and athletic,
      Scout is as tough as he looks. Scout believes that the best offense is a
      good defense, making Woofys feel safe during instability.
    </>
  ),
  bolt: (
    <>
      Bolt is defined by his <Bold color="#9061c4">logic</Bold> and{" "}
      <Bold color="#9061c4">persistence</Bold>. Discerning, determined, and
      independent, Bolt drew other Woofys to his pack by showing, not telling.
      Although Bolt’s pack shares his practicality, they have a tendency to let
      rationality run every decision they make.
    </>
  ),
  echo: (
    <>
      Echo is defined by his <Bold color="#5592fa">bravery</Bold> and{" "}
      <Bold color="#5592fa">tenacity</Bold>. Unpredictable, intense, and wild,
      Echo is drawn to the riskiest missions, and they usually pay off. Echo’s
      fearlessness borders on insanity, but this gift made him a popular pack
      leader among the Woofys.
    </>
  ),
  dottie: (
    <>
      Dottie is defined by her <Bold color="#9c63b8">intuition</Bold> and{" "}
      <Bold color="#9c63b8">groundedness</Bold>. Decisive, empathetic, and
      resourceful, Dottie emerged as a leader with her out-of-the-box ideas. An
      expert on horticulture and healing elixirs, Dottie is a formidable force.
    </>
  ),
  coco: (
    <>
      Coco is defined by her <Bold color="#e75152">intellect</Bold> and{" "}
      <Bold color="#e75152">calm</Bold>. Insightful, diplomatic, and cautious,
      Coco is a powerful leader with a detailed historical knowledge. No one has
      delved deeper into the Woofys’ existence than Coco, providing clarity and
      precision on survival tactics that few other packs possess.
    </>
  ),
};
const Woofys = () => {
  document.title = "Cozyverse | Woofys";
  const [woofy, setWoofy] = useState("ace");
  const [answers, setAnswers] = useState([1]);
  const [comicState, setComicState] = useState(false);

  return (
    <div className={styles.root}>
      <Section className={styles.section1}>
        <div className={styles.content}>
          <Avatar
            img="/assets/images/collection/woofys/1/logo_woofy.png"
            className={styles.logoWoofy}
          />
          <div className={styles.subtitle}>Pioneers of Web3 gaming</div>
          <div className={styles.buttonsWrapper}>
            <CssButton
              onClick={() => {
                window.open(
                  "https://avax.hyperspace.xyz/launchpad/5ad14893-3f7e-4be2-9205-d2122591c9f2",
                  "_blank",
                );
              }}
              text="Purchase"
              color="#42e6e8"
              shadowSize="5px"
              className={styles.button}
            />
          </div>
          <div className={styles.sign} style={{ paddingTop: "2rem" }}>
            <div className={styles.panel}></div>
            <div className={styles.text}>Purchase a Woofy on Hyperspace!</div>
          </div>
        </div>
        <Avatar
          img="/assets/images/collection/woofys/1/ace_big.png"
          className={styles.aceBig}
        />
        <Avatar
          img="/assets/images/collection/woofys/1/mining_axe1.png"
          className={styles.iceAxe}
        />
        <Avatar
          img="/assets/images/collection/woofys/1/snow_f.png"
          className={styles.snowfront}
        />
        <Avatar
          img="/assets/images/collection/woofys/1/snow_b.png"
          className={styles.snowbehind}
        />{" "}
        {snowflakes}
      </Section>
      <Section className={styles.section2}>
        <div className={styles.title}>Meet the Woofys</div>
        <div className={styles.content}>
          <div className={styles.textWrapper}>
            {" "}
            <div className={styles.text}>
              5,555 Woofys left their planet in search of a new home. Led by
              eight pack leaders, join them as they explore the{" "}
              <Bold color="#48cddf">Unknown Region</Bold> in their new journey!
              <br />
              <br />
              Woofys are the pioneers of web3 gaming and the digital identify of
              the Cozyverse. Explorer, build and play as your woofy. Are you
              ready to join the adventure?
            </div>
          </div>
          <CssButton
            onClick={() => {
              blockScroll();
              setComicState(true);
            }}
            text="Story"
            color="#42e6e8"
            shadowSize="5px"
            className={styles.button}
          />
        </div>
        <Avatar
          img="/assets/images/collection/woofys/1/snowborder.png"
          className={styles.snowborder}
        />
        <Avatar
          img="/assets/images/collection/woofys/1/snow_f.png"
          className={styles.snowfront}
        />
        <Avatar
          img="/assets/images/collection/woofys/1/snow_b.png"
          className={classNames(styles.snowbehind, styles.hide)}
          style={{ filter: "brightness(1.2)  saturate(0.5) blur(13px)" }}
        />{" "}
        <ComicStoryChapter3 open={comicState} setOpen={setComicState} />
        {snowflakes}
      </Section>
      <Section className={styles.section3}>
        <div className={styles.title}>Pack leaders</div>
        <div className={styles.content}>
          <div className={styles.leadersWrapper}>
            <Avatar
              img={`/assets/images/collection/woofys/1/card_${woofy}.png`}
              className={styles.leaderImg}
            />
            <div className={styles.leadersGrid}>
              {woofyNames.map((name) => (
                <div
                  key={name}
                  className={classNames(styles.cell, {
                    [styles.active]: woofy === name,
                  })}
                  onClick={() => setWoofy(name)}
                >
                  <img src={`/assets/images/collection/woofys/1/${name}.png`} />
                </div>
              ))}
            </div>
            <div className={styles.leaderAbout}>
              <div className={styles.text}>{about[woofy]}</div>
            </div>
          </div>
        </div>
        <Avatar
          img="/assets/images/collection/woofys/1/capsule.png"
          className={styles.capsule}
        />
        <Avatar
          img="/assets/images/collection/woofys/1/snow_f.png"
          className={styles.snowfront}
        />
        <Avatar
          img="/assets/images/collection/woofys/1/snow_b.png"
          className={styles.snowbehind}
        />{" "}
        {snowflakes}
      </Section>
      <Section className={styles.section4}>
        <div className={styles.title}>The Rescue</div>
        <div className={styles.content}>
          <div className={styles.panelsWrapper}>
            <div className={styles.panel}>
              <div className={styles.title}>Cozy Explorers</div>
              <div className={styles.text}>
                {" "}
                Cozy Penguins received a distress call and must travel through
                the portal to dig out the Woofys
              </div>
              <div className={styles.buttons}>
                <div
                  className={styles.squareButton}
                  style={
                    {
                      "--bg-color": "#72f8e2",
                    } as React.CSSProperties
                  }
                  onClick={() => {
                    window.location.assign(RegionPolarPeaksPortal);
                  }}
                >
                  portal
                </div>
                <div
                  className={styles.squareButton}
                  style={
                    {
                      "--bg-color": "#72f8e2",
                    } as React.CSSProperties
                  }
                  onClick={() => {
                    window.location.assign(RegionPolarPeaksRescue);
                  }}
                >
                  rescue
                </div>
              </div>
            </div>
            <div className={styles.panel}>
              <div className={styles.title}>Woofy</div>
              <div className={styles.text}>
                {" "}
                The region locals dug out Woofys capsules and are selling them
                at the Bazaar
              </div>
              <div className={styles.buttons}>
                <div
                  className={styles.squareButton}
                  style={
                    {
                      "--bg-color": "#ffbb4f",
                      padding: "1rem 3rem",
                    } as React.CSSProperties
                  }
                  onClick={() =>
                    window.open(
                      "https://avax.hyperspace.xyz/launchpad/5ad14893-3f7e-4be2-9205-d2122591c9f2",
                      "_blank",
                    )
                  }
                >
                  go
                </div>
              </div>
            </div>
          </div>
          <div className={classNames(styles.sign, styles.small)}>
            <div className={styles.panel}></div>
            <div className={styles.text}>
              The Woofys are trapped and need your help!
            </div>
          </div>
        </div>
        <Avatar
          img="/assets/images/collection/woofys/1/pebbles.png"
          className={styles.pebbles}
        />
        <Avatar
          img="/assets/images/collection/woofys/1/snow_f2.png"
          className={styles.snowfront}
        />
        <Avatar
          img="/assets/images/collection/woofys/1/snow_b.png"
          className={classNames(styles.snowbehind, styles.hide)}
          style={{ filter: "brightness(1.6)  saturate(0.4) blur(12px)" }}
        />{" "}
        {snowflakes}
      </Section>
      <Section className={styles.section5}>
        {" "}
        <div className={styles.title}>FAQ</div>
        <div className={styles.content}>
          <div className={styles.questionsWrapper}>
            <div
              className={styles.question}
              onClick={() =>
                !answers.includes(1)
                  ? setAnswers((answers) => [...answers, 1])
                  : setAnswers((answers) =>
                      answers.filter((answer) => answer !== 1),
                    )
              }
            >
              <div className={styles.title}>What are the Woofys?</div>
              <div
                className={classNames(styles.answer, {
                  [styles.hide]: !answers.includes(1),
                })}
              >
                Woofys are a 5,555 NFT collection on the Avalanche C-Chain. They
                are our pioneers in web3 gaming, serving both as your digital
                identity in the Cozyverse and the main playable characters in
                our blockchain games.
              </div>
            </div>
            <div
              className={styles.question}
              onClick={() =>
                !answers.includes(2)
                  ? setAnswers((answers) => [...answers, 2])
                  : setAnswers((answers) =>
                      answers.filter((answer) => answer !== 2),
                    )
              }
            >
              <div className={styles.title}>Where can I buy a Woofy?</div>
              <div
                className={classNames(styles.answer, {
                  [styles.hide]: !answers.includes(2),
                })}
              >
                You can purchase a Woofy on the{" "}
                <a
                  className={styles.link}
                  href="https://avax.hyperspace.xyz/launchpad/5ad14893-3f7e-4be2-9205-d2122591c9f2"
                  target="_blank"
                >
                  Hyperspace Launchapd
                </a>
                .
              </div>
            </div>
            <div
              className={styles.question}
              onClick={() =>
                !answers.includes(3)
                  ? setAnswers((answers) => [...answers, 3])
                  : setAnswers((answers) =>
                      answers.filter((answer) => answer !== 3),
                    )
              }
            >
              <div className={styles.title}>
                What is the official Woofy contract address?
              </div>
              <div
                className={classNames(styles.answer, {
                  [styles.hide]: !answers.includes(3),
                })}
              >
                Official contract is:
                <br />
                <a
                  className={styles.link}
                  href="https://snowtrace.dev/address/0xbacd77ac0c456798e05de15999cb212129d90b70"
                  target="_blank"
                >
                  0xBaCD77aC0c456798e05de15999CB212129d90b70
                </a>
              </div>
            </div>
            <div
              className={styles.question}
              onClick={() =>
                !answers.includes(4)
                  ? setAnswers((answers) => [...answers, 4])
                  : setAnswers((answers) =>
                      answers.filter((answer) => answer !== 4),
                    )
              }
            >
              <div className={styles.title}>
                I own a Cozy Penguin, should I own a Woofy too?
              </div>
              <div
                className={classNames(styles.answer, {
                  [styles.hide]: !answers.includes(4),
                })}
              >
                Cozy Penguins are front and center on our broader gaming
                initiative, and the face of Cozy Labs. Cozy Penguins will be
                adventuring with Woofys in our upcoming on-chain game.
              </div>
            </div>
            <div
              className={styles.question}
              onClick={() =>
                !answers.includes(5)
                  ? setAnswers((answers) => [...answers, 5])
                  : setAnswers((answers) =>
                      answers.filter((answer) => answer !== 5),
                    )
              }
            >
              <div className={styles.title}>What sound does a Woofy make?</div>
              <div
                className={classNames(styles.answer, {
                  [styles.hide]: !answers.includes(5),
                })}
              >
                BAKR BA KRBKA RBKBA RBKARB BKAR B BBAKR AWHO AWHOAOWOOAW
                AWOOOOOO AWO AOW AOW AOW AWWWWWWWWWWWWWWWWWWWWWWWWAOWOAWO AW
                OHAO BA RK BA RK BAKR B KARBKBARBAKR BKA BRK BAKBR BAKR BAKRB
                AWOR OO ABARK BAKR BAK RBK ABRK ABRK BAK BA BK ABK ARBKAWK AWO
                AOW OAW OA WOOAWOOBARK BKARARA BADK BARK
              </div>
            </div>
          </div>
        </div>
        <Avatar
          img="/assets/images/collection/woofys/1/snow_f2.png"
          className={styles.snowfront}
        />
        <Avatar
          img="/assets/images/collection/woofys/1/snow_b.png"
          className={styles.snowbehind}
          style={{ filter: "brightness(1.6)  saturate(0.4) blur(12px)" }}
        />{" "}
        {snowflakes}
      </Section>
    </div>
  );
};

export default Woofys;
