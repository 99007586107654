import Section from "../../../components/Section";
import Avatar from "../../../components/Avatars";
import {
  AppStoreButton,
  AppStoreInfo,
  AppStoreType,
  Description,
  Game,
  GameCard,
  Preview,
  Thumbnails,
} from "../../../components/GamePage";
import { ReactNode } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";
import { GamesWaddleWars } from "../../../constants/links";
import { Helmet } from "react-helmet";

const Subtitle = "An endless Cozy castle defender";

const Buttons = [
  <AppStoreButton
    className={styles.appStoreMobileButtons}
    href="https://apps.apple.com/us/app/waddle-wars-roguelike-defense/id6448682619"
    appStore={AppStoreType.apple}
  />,
  <AppStoreButton
    className={styles.appStoreMobileButtons}
    href="https://play.google.com/store/apps/details?id=xyz.cozylabs.waddlewars"
    appStore={AppStoreType.android}
  />,
];

const WaddleWarsGame = ({ buttons }: { buttons: ReactNode[] }) => {
  const unityConfig = {
    loaderUrl: "/assets/build/waddlewars/WaddleWars.loader.js",
    dataUrl: "/assets/build/waddlewars/WaddleWars.data",
    frameworkUrl: "/assets/build/waddlewars/WaddleWars.framework.js",
    codeUrl: "/assets/build/waddlewars/WaddleWars.wasm",
  };

  return (
    <Game
      unityConfig={unityConfig}
      titleSrc={"/assets/images/games/waddle_wars/logo_waddle_wars.png"}
      subtitle={Subtitle}
      buttons={buttons}
    />
  );
};

export const WaddleWarsPreview = ({}: {}) => {
  return (
    <Preview
      titleSrc={"/assets/images/games/waddle_wars/logo_waddle_wars.png"}
      cardSrc={"/assets/images/games/waddle_wars/preview_waddle_wars.png"}
      subtitle={Subtitle}
      href={GamesWaddleWars}
    />
  );
};

const WaddleWarsHero = ({
  className,
  playable = true,
}: {
  className?;
  playable?: boolean;
}) => {
  return (
    <Section className={classNames(styles.hero, className)}>
      <Helmet>
        <title>Cozyverse | Play Waddle Wars</title>
        <meta name="apple-itunes-app" content="app-id=6448682619" />
      </Helmet>
      {playable ? <WaddleWarsGame buttons={Buttons} /> : <WaddleWarsPreview />}
      <Avatar
        img="/assets/images/games/waddle_wars/ww_floor.png"
        hideMobile
        style={{
          bottom: "-0.5rem",
          width: "101%",
        }}
      />
      <Avatar
        img="/assets/images/games/waddle_wars/ww_l.png"
        hideMobile
        style={{
          bottom: "1rem",
          left: "-1rem",
          maxWidth: "60%",
        }}
      />
      <Avatar
        img="/assets/images/games/waddle_wars/ww_r.png"
        hideMobile
        style={{
          bottom: "1rem",
          right: "-2rem",
          maxWidth: "25%",
        }}
      />
    </Section>
  );
};

const WaddleWarsPage = () => {
  return (
    <div className={styles.root}>
      <WaddleWarsHero playable={true} />
      <Section className={styles.about}>
        <AppStoreInfo
          iconImg="/assets/images/games/waddle_wars/icon_waddle_wars.png"
          buttons={[
            <AppStoreButton
              className={styles.appStoreDesktopButtons}
              href="https://apps.apple.com/us/app/waddle-wars-roguelike-defense/id6448682619"
              appStore={AppStoreType.apple}
            />,
            <AppStoreButton
              className={styles.appStoreDesktopButtons}
              href="https://play.google.com/store/apps/details?id=xyz.cozylabs.waddlewars"
              appStore={AppStoreType.android}
            />,
          ]}
        />
        <Description>
          <p>
            Get ready for a cozy adventure in "Waddle Wars" by Cozy Labs! Play
            as the hero penguin in a unique blend of tower defense and roguelike
            gameplay as you defend your castle from waves of cute yet pesky
            invaders. But that's not all - after each wave, select from 30+
            different perks to upgrade your defenses. Summon guards, upgrade
            your castle, level up your hero, and more. Complete quests to unlock
            new hero skins and compete for bragging rights on the local and
            multiplayer high score tables.
          </p>
          <h3>Features</h3>
          <ul>
            <li>
              Strategic Upgrades: After each wave, choose from 30+ unique perks
              to bolster your defenses, summon guards, and upgrade your castle,
              hero, and guards.
            </li>
            <li>
              Unlockable Skins: Complete quests to unlock a variety of hero
              skins and showcase your achievements.
            </li>
            <li>
              Global Competition: Challenge friends and players from around the
              world on local and multiplayer high score tables.
            </li>
          </ul>
          <p>
            Can you defend your castle and become the ultimate hero in this cozy
            tower defense adventure? Get ready to waddle your way to victory and
            become the ultimate Waddle Wars champion!
          </p>
          <h3>How to Play</h3>
          <ul>
            <li>
              Hurl projectiles <b>[CLICK/HOLD]</b> to defend your castle.
            </li>
            <li>
              Choose from a random selection of <b>UNITS</b> and <b>PERKS</b> to
              upgrade your defense.
            </li>
            <li>
              <b>REROLL</b> the <b>PERKS</b> by spending coins dropped by
              enemies or mined by <b>MINERS</b>.
            </li>
          </ul>
          <h3>Patch Notes (v1.1.0)</h3>
          <h4>Guards</h4>
          <ul>
            <li>Clerics: now buff 2 guards at once.</li>
            <li>Fisherman: reduced health, increased damage.</li>
            <li>Defender: Increased health, reduced damage.</li>
          </ul>
          <h4>Enemies</h4>
          <ul>
            <li>Imposter: projectile is destroyed on KO.</li>
          </ul>
          <h4>Perks</h4>
          <ul>
            <li>TKO: trigger chance 10% {"->"} 5%.</li>
            <li>Slow / Poison: 10% {"->"} 25% chance</li>
          </ul>
        </Description>
        <Thumbnails>
          <img src="/assets/images/games/waddle_wars/1.png" />
          <img src="/assets/images/games/waddle_wars/2.png" />
          <img src="/assets/images/games/waddle_wars/3.png" />
          <img src="/assets/images/games/waddle_wars/4.png" />
          <img src="/assets/images/games/waddle_wars/5.png" />
          <img src="/assets/images/games/waddle_wars/6.png" />
        </Thumbnails>
        <Avatar
          img="/assets/images/games/shared/ducks.png"
          hideMobile
          style={{
            left: "-15rem",
            bottom: "-10rem",
          }}
        />
        <Avatar
          img="/assets/images/games/shared/ducks.png"
          hideMobile
          style={{
            right: "-15rem",
            top: "-10rem",
          }}
        />
      </Section>
    </div>
  );
};

export { WaddleWarsPage, WaddleWarsHero };
