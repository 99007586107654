import { CSSProperties, ReactNode } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";

const Card = ({
  title,
  content,
  color,
  fontSize,
  style,
  className = null,
}: {
  title: string;
  content: string | ReactNode;
  color: string;
  fontSize?: string;
  style?: CSSProperties;
  className?: string;
}) => {
  return (
    <div className={styles.card} style={style}>
      <div
        className={styles.title}
        style={
          {
            "--color": color,
            "--font-size": fontSize,
          } as CSSProperties
        }
      >
        {title}
      </div>
      <div className={classNames(styles.content, className)}>{content}</div>
    </div>
  );
};

export default Card;
