import { ComicPanel, ComicRoot } from "../index";
import styles from "./index.module.scss";
import { CssButton } from "../../Buttons";
import { RegionCozyReef, RegionPolarPeaks } from "../../../constants/links";

const ComicStoryChapter1 = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  return (
    <ComicRoot
      open={open}
      setOpen={setOpen}
      panels={[
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/page%2000001.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/page%2000002.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/page%2000003.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/page%2000004.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/page%2000005.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/page%2000006.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/page%2000007.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/page%2000008.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/page%2000009.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/page%2000010.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/page%2000011.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/page%2000012.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/page%2000013.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/page%2000014.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/page%2000015.png" />
        </ComicPanel>,
      ]}
      numPreviewPanels={5}
      endItems={[
        <CssButton
          smallMobile
          text="Visit Cozy Reef"
          onClick={() => {
            window.location.assign(RegionCozyReef);
          }}
          color="#aaeb49"
        />,
      ]}
    />
  );
};

const ComicStoryChapter2 = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  return (
    <ComicRoot
      open={open}
      setOpen={setOpen}
      panels={[
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/2/page001.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/2/page002.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/2/page003.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/2/page004.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/2/page005.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/2/page006.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/2/page007.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/2/page008.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/2/page009.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/cozypenguins/comics/2/page010.png" />
        </ComicPanel>,
      ]}
      showPreview={false}
      endItems={[
        <div className={styles.postTitle}>THE JOURNEY BEGINS SOON!</div>,
      ]}
    />
  );
};

const ComicStoryChapter3 = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  return (
    <ComicRoot
      open={open}
      setOpen={setOpen}
      extraPadding={true}
      panels={[
        <ComicPanel>
          <img src="/assets/images/collection/woofys/comics/1.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/woofys/comics/2.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/woofys/comics/3.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/woofys/comics/4.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/woofys/comics/5.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/woofys/comics/6.png" />
        </ComicPanel>,
        <ComicPanel>
          <img src="/assets/images/collection/woofys/comics/7.png" />
        </ComicPanel>,
      ]}
      endItems={[
        <CssButton
          className={styles.button}
          text="Visit Unknown"
          onClick={() => {
            window.location.assign(RegionPolarPeaks);
          }}
          color="#42e6e8"
        />,
      ]}
    />
  );
};

export { ComicStoryChapter1, ComicStoryChapter2, ComicStoryChapter3 };
