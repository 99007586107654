import classNames from "classnames";
import { ImageButton } from "../Buttons";
import Avatar from "../Avatars";
import styles from "./index.module.scss";
import { CollectionsCozyPenguins, RegionCozyReefPortal } from "constants/links";
import { AppStoreButton, AppStoreType } from "../GamePage";
import { CssButton } from "../Buttons";

const MobileCozyReefRegion = ({
  logoSrc,
  setOpen,
}: {
  logoSrc: string;
  setOpen: (boolean) => void;
}) => {
  return (
    <div className={styles.mobileContainer}>
      <div className={styles.topLogo}>
        <img
          className={styles.logo}
          src="/assets/images/region/cozyreef_mobile/panel.png"
        />
      </div>

      <div className={styles.mobileSlideContainer}>
        <img
          className={styles.slideImage}
          src="/assets/images/region/cozyreef_mobile/waddle_wars.png"
        />
        <div
          className={classNames(
            styles.mobileSlideRightInfo,
            styles.waddleWarsInfo,
          )}
        >
          <div className={styles.mobileSlideLogoContainer}>
            <img
              className={styles.mobileLogo}
              src="/assets/images/region/cozyreef_mobile/logo_waddlewars.png"
            />
          </div>
          <div className={styles.mobileSlideButtonsContainer}>
            <AppStoreButton
              appStore={AppStoreType.apple}
              href="https://apps.apple.com/us/app/waddle-wars-roguelike-defense/id6448682619"
            />

            <AppStoreButton
              appStore={AppStoreType.android}
              href="https://play.google.com/store/apps/details?id=xyz.cozylabs.waddlewars"
            />
          </div>
        </div>
      </div>

      <div className={styles.mobileSlideContainer}>
        <img
          className={styles.slideImage}
          src="/assets/images/region/cozyreef_mobile/land_slide.png"
        />
        <div className={styles.mobileSlideLeftInfo}>
          <div className={styles.mobileSlideLogoContainer}>
            <img
              className={styles.mobileLogo}
              src="/assets/images/region/cozyreef_mobile/logo_landslide.png"
            />
          </div>
          <div className={styles.mobileSlideButtonsContainer}>
            <AppStoreButton
              appStore={AppStoreType.apple}
              href="https://apps.apple.com/us/app/landslide-by-cozy-labs/id6450430184"
            />

            <AppStoreButton
              appStore={AppStoreType.android}
              href="https://play.google.com/store/apps/details?id=xyz.cozylabs.landslide"
            />
          </div>
        </div>
      </div>

      <div className={styles.mobileSlideContainer}>
        <img
          className={styles.slideImage}
          src="/assets/images/region/cozyreef_mobile/cozy_labs.png"
        />
        <div className={classNames(styles.mobileSlideRightInfo)}>
          <div className={styles.mobileSlideLogoContainer}>
            <img
              className={styles.mobileLogo}
              src="/assets/images/region/cozyreef_mobile/logo_cozylabs.png"
            />
          </div>
          <div className={styles.mobileSlideButtonsContainer}>
            <CssButton
              className={styles.readNowButton}
              onClick={() => {
                setOpen(true);
              }}
              text="Read now"
            />
          </div>
        </div>
      </div>

      <div
        className={classNames(
          styles.mobileSlideContainer,
          styles.cozyPenguinsSlide,
        )}
      >
        <img
          className={styles.slideImage}
          src="/assets/images/region/cozyreef_mobile/cozy_penguins.png"
        />
        <div
          className={classNames(
            styles.mobileSlideLeftInfo,
            styles.cozyPenguinsInfo,
          )}
        >
          <div className={styles.mobileSlideLogoContainer}>
            <img
              className={styles.mobileLogo}
              src="/assets/images/region/cozyreef_mobile/logo_cozypenguins.png"
            />
          </div>
          <div className={styles.mobileSlideButtonsContainer}>
            <CssButton
              className={styles.learnMoreButton}
              onClick={() => {
                window.location.assign(CollectionsCozyPenguins);
              }}
              text="Learn more"
            />
          </div>
        </div>
      </div>

      <div className={styles.mobileSlideContainer}>
        <img
          className={styles.slideImage}
          src="/assets/images/region/cozyreef_mobile/portal.png"
        />
        <div className={classNames(styles.mobileSlideRightInfo)}>
          <div className={styles.mobileSlideLogoContainer}>
            <img
              className={styles.mobileLogo}
              src="/assets/images/region/cozyreef_mobile/logo_portal.png"
            />
          </div>
          <div className={styles.mobileSlideButtonsContainer}>
            <CssButton
              className={styles.enterPortalButton}
              onClick={() => {
                window.location.assign(RegionCozyReefPortal);
              }}
              text="Enter"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileCozyReefRegion;
