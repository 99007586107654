import classNames from "classnames";
import styles from "./index.module.scss";

const Section = ({ className, children }: { className?: string; children }) => {
  return (
    <div className={classNames(className, styles.section)}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Section;
