import toast from "react-hot-toast";
import { getMessageFromCode } from "eth-rpc-errors";

export const withErrorToast = <T,>(
  promise: Promise<T>,
  options?: {
    error?: string;
  },
) => {
  return promise.catch((e) => {
    toastError(e, options?.error);
    throw e;
  });
};

export const withPromiseToast = (
  promiseFunc: any,
  options?: {
    loading?: string;
    error?: string;
    success?: string;
  },
) => {
  const error = options?.error || "Something bad happened";
  const loading = options?.loading || "Processing...";
  const success = options?.success || "Completed!";

  return toast
    .promise(promiseFunc(), {
      loading,
      success,
      error: (e) => {
        return getErrorMessage(e, error);
      },
    })
    .catch((e) => getErrorMessage(e, error));
};

export const toastError = (e: any, errorMessage?: string) => {
  toast.error(getErrorMessage(e, errorMessage));
};

export const getErrorMessage = (e: any, errorMessage?: string) => {
  if (errorMessage) {
    return errorMessage;
  } else if (e.error && e.error.message) {
    return e.error.message;
  } else if (e.code) {
    return getMessageFromCode(e.code);
  } else {
    return "An unknown error occurred!";
  }
};
