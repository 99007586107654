import styles from "./index.module.scss";
import Grid from "../Grid";
import { Button } from "../../Buttons";
import { RegionCozyReef, RegionPolarPeaks } from "../../../constants/links";
import classNames from "classnames";

const PenguinSelect = ({
  title,
  balance,
  cozyPenguins,
  selected,
  toggleSelected,
  loaded,
  bridgeLimit,
  selectedAmount,
  hideBackBtn,
}: {
  title?: string;
  balance: number;
  cozyPenguins: number[];
  selected: boolean[];
  toggleSelected: (i) => void;
  loaded: boolean;
  bridgeLimit: number;
  selectedAmount: number;
  hideBackBtn?: boolean;
}) => {
  return (
    <div className={styles.penguinSelectWrapper}>
      <Button
        className={classNames(styles.backButton, {
          [styles.hideBackBtn]: hideBackBtn,
        })}
        onMouseDown={() => {
          title.includes("cozyreef")
            ? window.location.assign(RegionCozyReef)
            : window.location.assign(RegionPolarPeaks);
        }}
      >
        <div></div>
      </Button>
      <div
        className={classNames(styles.bridgeLimit, {
          [styles.red]: selectedAmount === bridgeLimit,
        })}
      >
        <div>
          {selectedAmount}/{bridgeLimit}
        </div>
      </div>
      <div className={styles.penguinSelect}>
        <Grid
          title={title}
          balance={balance}
          gridItems={cozyPenguins}
          loaded={loaded}
          selected={selected}
          toggleSelected={toggleSelected}
        />
      </div>
    </div>
  );
};

export default PenguinSelect;
