import styles from "../index.module.scss";
import classNames from "classnames";
import * as PIXI from "pixi.js";
import { GlowFilter, DropShadowFilter } from "pixi-filters";
import Map from "../../../components/Maps";
import { useEffect, useRef, useState } from "react";
import { useImmer } from "use-immer";
import { HEIGHT, WIDTH } from "../../../components/Maps/constants";
import { ModalRoot } from "../../../components/Modal";
import AudioManager, { AudioGroup } from "../../../components/AudioManager";
import Banner from "components/Banner";
import MobilePolarPeaksRegion from "../../../components/Regions/MobilePolarPeaksRegion";
import { VolumeButton } from "../../../components/hud";
import {
  Portal,
  Rescue,
  Campsite,
  Bazaar,
} from "../../../components/Maps/Cards";

export const rescueEnabled = true;

class Title extends PIXI.Container {
  image: PIXI.Sprite;
  onClick: PIXI.utils.EventEmitter.ListenerFn;

  constructor(image: PIXI.Sprite, onClick: PIXI.utils.EventEmitter.ListenerFn) {
    super();

    this.image = image;
    this.onClick = AudioManager.instance.onClick(onClick);

    const glowFilter = new GlowFilter({
      color: 0xffffff,
      distance: 7,
      outerStrength: 50,
      innerStrength: 0,
      quality: 0.2,
      knockout: false,
    });

    const dropShadowFilter = new DropShadowFilter({
      distance: 7,
      rotation: 90,
      blur: 0.1,
      color: 0x000000,
      alpha: 1,
    });

    const filters = [glowFilter, dropShadowFilter];

    const initialFilters = this.image.filters ?? [];

    this.image.interactive = true;
    this.image.cursor = "pointer";
    this.image.on("pointerup", this.onClick);
    this.image.on("pointerover", () => {
      this.image.filters = [...initialFilters, ...filters];
    });
    this.image.on("pointerout", () => {
      this.image.filters = initialFilters;
    });

    this.addChild(this.image);
  }
}

const PolarPeaks = () => {
  const canvas = useRef<HTMLCanvasElement>();
  const [state, setState] = useImmer({
    modal: null,
    showComic: false,
  });
  const root = useRef<HTMLDivElement>();

  useEffect(() => {
    function centerXRootElement() {
      if (!root.current) {
        return;
      }
      const screenWidth = window.innerWidth;
      const scrollLeft = (root.current.scrollWidth - screenWidth) / 2;
      root.current.scrollLeft = scrollLeft;
    }
    centerXRootElement();
    window.addEventListener("resize", centerXRootElement);
    return () => window.removeEventListener("resize", centerXRootElement);
  }, [root]);

  useEffect(() => {
    const initialize = async () => {
      if (!canvas.current) {
        return;
      }

      const app = new PIXI.Application({
        view: canvas.current,
        antialias: true,
        autoDensity: true,
        autoStart: false,
        sharedTicker: false,
        sharedLoader: false,
        width: WIDTH,
        height: HEIGHT,
      });

      let moveY = 50;
      const onAssetLoad = (loader, res) => {
        const bg = app.stage.addChild(
          new PIXI.TilingSprite(
            res.bg.texture,
            app.view.width,
            app.view.height,
          ),
        );
        bg.tileScale.x = 0.9;
        bg.tileScale.y = 1;

        for (let j = 0; j < 2; j++) {
          const light = new PIXI.TilingSprite(
            res.light.texture,
            app.screen.width * 4,
            res.light.texture.height,
          );
          light.position.set(-j * 300, -250 + j * 300);

          light.alpha = 0.25 - j * 0.05;
          app.stage.addChild(light);
          app.ticker.add(() => {
            light.x -= 1;
            if (light.x + light.width < app.screen.width) {
              light.x = -app.screen.width + 500;
            }
          });
        }

        const clouds = [];
        for (let i = 0; i < 2; i++) {
          const cloud = new PIXI.Sprite(res.cloud.texture);
          cloud.scale.x = 1.11;
          cloud.scale.y = 1;
          cloud.position.set(cloud.width * i, 640 + moveY);
          app.stage.addChild(cloud);
          clouds.push(cloud);
        }

        app.ticker.add(() => {
          for (const cloud of clouds) {
            cloud.x -= 0.1; // The speed of the clouds
            if (cloud.x + cloud.width < 0) {
              cloud.x = app.screen.width;
            }
          }
        });

        const stars1 = app.stage.addChild(new PIXI.Sprite(res.stars1.texture));
        stars1.position.set(150, 200 + moveY);
        stars1.scale.x = 1.2;
        stars1.scale.y = 1;

        const stars2 = app.stage.addChild(new PIXI.Sprite(res.stars2.texture));
        stars2.position.set(350, 300 + moveY);
        stars2.scale.x = 1;
        stars2.scale.y = 0.9;

        const shadow = app.stage.addChild(new PIXI.Sprite(res.shadow.texture));
        shadow.position.set(420, 930 + moveY);
        shadow.scale.x = 0.8;
        shadow.scale.y = 0.8;

        const mountains4 = app.stage.addChild(
          new PIXI.Sprite(res.mountains4.texture),
        );
        mountains4.position.set(420, 342 + moveY);
        mountains4.scale.x = 0.8;
        mountains4.scale.y = 0.85;

        const mountains3 = app.stage.addChild(
          new PIXI.Sprite(res.mountains3.texture),
        );
        mountains3.position.set(680, 368 + moveY);
        mountains3.scale.x = 1;
        mountains3.scale.y = 1;

        const mountains2 = app.stage.addChild(
          new PIXI.Sprite(res.mountains2.texture),
        );
        mountains2.position.set(600, 500 + moveY);
        mountains2.scale.x = 0.8;
        mountains2.scale.y = 0.8;

        const mountains1 = app.stage.addChild(
          new PIXI.Sprite(res.mountains1.texture),
        );
        mountains1.position.set(450, 250 + moveY);
        mountains1.scale.x = 0.8;
        mountains1.scale.y = 0.8;

        const bazaar = app.stage.addChild(new PIXI.Sprite(res.bazaar.texture));
        bazaar.position.set(830, 366 + moveY);
        bazaar.scale.x = 0.8;
        bazaar.scale.y = 0.8;

        const bazaarlogo = app.stage.addChild(
          new Title(new PIXI.Sprite(res.bazaarlogo.texture), () => {
            setState((state) => {
              state.modal = "bazaarlogo";
            });
          }),
        );
        bazaarlogo.position.set(840, 580 + moveY);
        bazaarlogo.scale.x = 0.55;
        bazaarlogo.scale.y = 0.55;

        const cave = app.stage.addChild(new PIXI.Sprite(res.cave.texture));
        cave.position.set(1650, 800 + moveY);
        cave.scale.x = 0.8;
        cave.scale.y = 0.8;

        const capsule = app.stage.addChild(
          new PIXI.Sprite(res.capsule.texture),
        );
        capsule.position.set(1440, 875 + moveY);
        capsule.scale.x = 0.8;
        capsule.scale.y = 0.8;

        if (rescueEnabled) {
          const rescuelogo = app.stage.addChild(
            new Title(new PIXI.Sprite(res.rescuelogo.texture), () => {
              setState((state) => {
                state.modal = "rescuelogo";
              });
            }),
          );
          rescuelogo.position.set(1420, 1040 + moveY);
          rescuelogo.scale.x = 0.3;
          rescuelogo.scale.y = 0.3;
        }

        const mining = app.stage.addChild(new PIXI.Sprite(res.mining.texture));
        mining.position.set(1020, 730 + moveY);
        mining.scale.x = 0.8;
        mining.scale.y = 0.8;

        const portal = app.stage.addChild(new PIXI.Sprite(res.portal.texture));
        portal.position.set(810, 810 + moveY);
        portal.scale.x = 0.8;
        portal.scale.y = 0.8;

        const woofyhome = app.stage.addChild(
          new PIXI.Sprite(res.woofyhome.texture),
        );
        woofyhome.position.set(1470, 571 + moveY);
        woofyhome.scale.x = 0.8;
        woofyhome.scale.y = 0.8;

        const campsitelogo = app.stage.addChild(
          new Title(new PIXI.Sprite(res.campsitelogo.texture), () => {
            setState((state) => {
              state.modal = "campsitelogo";
            });
          }),
        );
        campsitelogo.position.set(1560, 660 + moveY);
        campsitelogo.scale.x = 0.65;
        campsitelogo.scale.y = 0.65;

        const portallogo = app.stage.addChild(
          new Title(new PIXI.Sprite(res.portallogo.texture), () => {
            setState((state) => {
              state.modal = "portallogo";
            });
          }),
        );
        portallogo.position.set(830, 1010 + moveY);
        portallogo.scale.x = 0.8;
        portallogo.scale.y = 0.8;
      };
      app.loader
        .add("bg", "/assets/images/region/polarpeaks/bg.png", {
          crossOrigin: true,
        })
        .add("cloud", "/assets/images/region/polarpeaks/clouds.png", {
          crossOrigin: true,
        })
        .add("light", "/assets/images/region/polarpeaks/lights.png", {
          crossOrigin: true,
        })
        .add("shadow", "/assets/images/region/polarpeaks/shadow.png", {
          crossOrigin: true,
        })
        .add("mountains4", "/assets/images/region/polarpeaks/mountains4.png", {
          crossOrigin: true,
        })
        .add("mountains3", "/assets/images/region/polarpeaks/mountains3.png", {
          crossOrigin: true,
        })
        .add("mountains2", "/assets/images/region/polarpeaks/mountains2.png", {
          crossOrigin: true,
        })
        .add("mountains1", "/assets/images/region/polarpeaks/mountains1.png", {
          crossOrigin: true,
        })
        .add("stars1", "/assets/images/region/polarpeaks/stars1.png", {
          crossOrigin: true,
        })
        .add("stars2", "/assets/images/region/polarpeaks/stars2.png", {
          crossOrigin: true,
        })
        .add("bazaar", "/assets/images/region/polarpeaks/bazaar.png", {
          crossOrigin: true,
        })
        .add(
          "bazaarlogo",
          "/assets/images/region/polarpeaks/bazaar/logo_bazaar.png",
          {
            crossOrigin: true,
          },
        )
        .add("cave", "/assets/images/region/polarpeaks/cave.png", {
          crossOrigin: true,
        })
        .add("capsule", "/assets/images/region/polarpeaks/capsule.png", {
          crossOrigin: true,
        })
        .add("mining", "/assets/images/region/polarpeaks/mining.png", {
          crossOrigin: true,
        })
        .add("portal", "/assets/images/region/polarpeaks/portal.png", {
          crossOrigin: true,
        })
        .add("woofyhome", "/assets/images/region/polarpeaks/woofyhome.png", {
          crossOrigin: true,
        })
        .add("portallogo", "/assets/images/region/polarpeaks/logo_portal.png", {
          crossOrigin: true,
        })
        .add(
          "campsitelogo",
          "/assets/images/region/polarpeaks/campsite/campsite.png",
          {
            crossOrigin: true,
          },
        );

      rescueEnabled &&
        app.loader.add(
          "rescuelogo",
          "/assets/images/region/polarpeaks/logo_rescue.png",
          {
            crossOrigin: true,
          },
        );

      app.loader.load(onAssetLoad);

      app.start();
    };
    initialize();
    return () => {
      PIXI.utils.clearTextureCache();
    };
  }, [canvas]);

  const soundRef = useRef<HTMLDivElement>();
  const [muted, setMuted] = useState(false);

  useEffect(() => {
    const howl = AudioManager.instance.play(
      {
        src: "/assets/sounds/region/polarpeaks/map_polarpeaks.wav",
        volume: 0.35,
        loop: true,
      },
      AudioGroup.BG,
    );

    const sound = soundRef.current;
    if (sound) {
      const computedStyle = getComputedStyle(sound);
      const displayValue = computedStyle.getPropertyValue("display");
      if (displayValue === "none") {
        setMuted(true);
      }
    }

    return () => {
      howl.stop();
    };
  }, []);

  const snowflakes = [];
  for (let i = 0; i < 50; i++) {
    snowflakes.push(<div key={i} className={styles.snowflake}></div>);
  }

  return (
    <>
      <div className={classNames(styles.root, styles.polarPeaks)} ref={root}>
        <div className={styles.body}>
          <Map>
            <canvas ref={canvas} />
            {state.modal === "portallogo" && (
              <ModalRoot
                nobg
                transparentblur
                scrollable={false}
                open={true}
                closeButton="arrow"
                onClose={() => {
                  setState((state) => {
                    state.modal = null;
                  });
                }}
              >
                <Portal region="polarpeaks" />
              </ModalRoot>
            )}
            {state.modal === "rescuelogo" && (
              <ModalRoot
                nobg
                transparentblur
                scrollable={false}
                open={true}
                closeButton="arrow"
                onClose={() => {
                  setState((state) => {
                    state.modal = null;
                  });
                }}
              >
                <Rescue />
              </ModalRoot>
            )}
            {state.modal === "campsitelogo" && (
              <ModalRoot
                nobg
                transparentblur
                scrollable={false}
                open={true}
                closeButton="arrow"
                onClose={() => {
                  setState((state) => {
                    state.modal = null;
                  });
                }}
              >
                <Campsite />
              </ModalRoot>
            )}
            {state.modal === "bazaarlogo" && (
              <ModalRoot
                nobg
                transparentblur
                scrollable={false}
                open={true}
                closeButton="arrow"
                onClose={() => {
                  setState((state) => {
                    state.modal = null;
                  });
                }}
              >
                <Bazaar />
              </ModalRoot>
            )}
            {!state.modal && (
              <div className={styles.bannerWrapper}>
                <Banner text="Polar Peaks" color="red" />
              </div>
            )}
          </Map>
        </div>
        <MobilePolarPeaksRegion logoSrc="/assets/images/region/polarpeaks/logo_polarpeaks.png" />
      </div>
      <div
        ref={soundRef}
        className={classNames(styles.sound, {
          [styles.hide]: state.modal,
        })}
      >
        <VolumeButton className={styles.volume} muted={muted} />
      </div>
      {snowflakes}
    </>
  );
};

export default PolarPeaks;
