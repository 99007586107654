import Section from "../../../components/Section";
import Avatar from "../../../components/Avatars";
import { Description } from "../../../components/GamePage";
import styles from "./index.module.scss";
import classNames from "classnames";

const FishNFriendsPage = () => {
  return (
    <div className={styles.root}>
      <Section className={styles.about}>
        <Avatar
          img="/assets/images/cozylabs_small.png"
          style={{
            height: "60px",
            top: "-8px",
          }}
        />
        <Description>
          <a
            className={styles.linkButton}
            href="https://cozyverse.xyz/game/waddle-wars"
          >
            <div className={styles.text}>Play Waddle Wars</div>
          </a>
          <a
            className={styles.linkButton}
            href="https://cozyverse.xyz/game/landslide"
          >
            <div className={styles.text}>Play Landslide</div>
          </a>
          <a
            className={styles.linkButton}
            href="https://discord.com/invite/cozyverse"
          >
            <div className={styles.text}>Join our discord</div>
          </a>
          <a className={styles.linkButton} href="https://x.com/cozylabsxyz">
            <div className={styles.text}>Follow us on Twitter</div>
          </a>
          <a
            className={styles.linkButton}
            href="https://avax.hyperspace.xyz/collection/avax/5ad14893-3f7e-4be2-9205-d2122591c9f2"
          >
            <div className={styles.text}>Woofys</div>
          </a>
          <a
            className={styles.linkButton}
            href="https://avax.hyperspace.xyz/collection/avax/cozy-penguin-explorers"
          >
            <div className={styles.text}>Cozy Penguin Explorers</div>
          </a>
        </Description>
        <Avatar
          img="/assets/images/games/shared/ducks.png"
          style={{
            height: "60px",
            left: "0rem",
            bottom: "0rem",
          }}
        />
      </Section>
    </div>
  );
};

export { FishNFriendsPage };
