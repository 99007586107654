import styles from "./index.module.scss";
import { useEffect, useRef } from "react";
import { allowScroll } from "../Scroll";
import classNames from "classnames";
import { ImageButton } from "../Buttons";

const ModalRoot = ({
  open,
  onClose,
  children,
  nobg = false,
  transparentblur = false,
  scrollable = true,
  center = false,
  closeButton,
}: {
  open: boolean;
  onClose: () => void;
  children;
  nobg?: boolean;
  transparentblur?: boolean;
  scrollable?: boolean;
  center?: boolean;
  closeButton?: string;
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
        allowScroll();
      }
    };
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div
      className={classNames(styles.modal, {
        [styles.hide]: !open,
        [styles.transparentblur]: transparentblur,
        [styles.scrollable]: scrollable,
        [styles.center]: center,
      })}
    >
      <div
        ref={ref}
        className={classNames(styles.container, {
          [styles.nobg]: nobg,
        })}
      >
        {children}
      </div>
      {closeButton === "arrow" ? (
        <ImageButton
          img="/assets/images/region/btn_back1.png"
          className={styles.arrowClose}
          disabled={false}
          onMouseDown={onClose}
        />
      ) : closeButton === "none" ? null : (
        <ImageButton
          className={styles.xClose}
          img="/assets/images/icons/icon_cross.png"
          onMouseDown={() => {
            onClose();
            allowScroll();
          }}
          bg
        />
      )}
    </div>
  );
};

const ModalBody = ({ children }) => {
  return <div className={styles.body}>{children}</div>;
};

const ModalTitle = ({ children }) => {
  return <div className={styles.title}>{children}</div>;
};

export { ModalRoot, ModalBody, ModalTitle };
