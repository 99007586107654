import { Howl, HowlOptions } from "howler";

export enum AudioGroup {
  BG,
  FX,
  GFX,
}

type Sound = {
  howl: Howl;
  volume: number;
};

export default class AudioManager {
  private static _instance: AudioManager;

  sounds: Record<AudioGroup, Set<Sound>>;
  currVolumes: Record<AudioGroup, number>;
  prevVolumes: Record<AudioGroup, number>;

  private constructor() {
    this.sounds = {
      [AudioGroup.BG]: new Set(),
      [AudioGroup.FX]: new Set(),
      [AudioGroup.GFX]: new Set(),
    };
    this.prevVolumes = {
      [AudioGroup.BG]: 1,
      [AudioGroup.FX]: 1,
      [AudioGroup.GFX]: 1,
    };
    this.currVolumes = {
      [AudioGroup.BG]: 1,
      [AudioGroup.FX]: 1,
      [AudioGroup.GFX]: 1,
    };
  }

  static get instance(): AudioManager {
    if (!this._instance) {
      this._instance = new AudioManager();
    }

    return this._instance;
  }

  play(settings: HowlOptions, group: AudioGroup) {
    const howl = this.build(settings, group);
    howl.play();
    return howl;
  }

  build(settings: HowlOptions, group: AudioGroup) {
    const volume = settings.volume ? settings.volume : 1;
    const groupVolume = this.currVolumes[group];
    const howl = new Howl({
      ...settings,
      volume: volume * groupVolume,
    });
    const sound = { howl, volume };

    this.sounds[group].add(sound);

    howl.on("stop", () => this.sounds[group].delete(sound));
    if (!settings.loop) {
      howl.on("end", () => this.sounds[group].delete(sound));
    }

    return howl;
  }

  volume(volume: number, group: AudioGroup) {
    this.currVolumes[group] = volume;
    this.sounds[group].forEach((sound) => {
      sound.howl.volume(volume * sound.volume);
    });
  }

  mute(muted: boolean, group: AudioGroup) {
    if (muted) {
      this.prevVolumes[group] = this.currVolumes[group];
      this.currVolumes[group] = 0;
    } else {
      this.currVolumes[group] = this.prevVolumes[group];
    }

    this.currVolumes[group] = muted ? 0 : this.prevVolumes[group];
    this.sounds[group].forEach((sound) => {
      sound.howl.mute(muted);
    });
  }

  onClick(handleClick?: Function) {
    return (e: React.UIEvent) => {
      this.play(
        {
          src: "/assets/sounds/click.mp3",
          volume: 1,
        },
        AudioGroup.FX,
      );

      if (handleClick) {
        handleClick(e);
      }
    };
  }
}
