import { useImmer } from "use-immer";
import classNames from "classnames";
import styles from "./index.module.scss";
import { CssButton } from "../../Buttons";
import { withPromiseToast } from "../../../utils";
import { ModalRoot } from "../../Modal";
import Card from "./Card";
import { useContext } from "react";
import { ContractsContext } from "../../../providers/ContractsProvider";
import { TravelTransactionsContext } from "../../../providers/TravelTransactionsProvider";
import { useAccount } from "wagmi";
import Avatar from "components/Avatars";
import { Bold } from "../../../components/Card";
import TextSign from "./TextSign";
import { GridCell } from "../Grid";
import { buildChainlinkCCIPLink } from "./TransactionHistory";
import { RegionCozyReef, RegionPolarPeaks } from "../../../constants/links";
import {
  buildCozyPenguinExplorerTokenImage,
  buildCozyPenguinTokenImage,
} from "../../../utils/web3";
import { ethers } from "ethers";

const TravelModal = ({
  region,
  cozyPenguins,
  selected,
  open,
  onClose,
  onSuccessfulTravel,
  fee,
}: {
  region: string;
  cozyPenguins: number[];
  selected: boolean[];
  open: boolean;
  onClose: () => void;
  onSuccessfulTravel: () => void;
  fee: string;
}) => {
  const contracts = useContext(ContractsContext);
  const travelTransactions = useContext(TravelTransactionsContext);
  const { isConnected, address } = useAccount();
  const [state, setState] = useImmer<{
    page: string;
    isTraveling: boolean;
    balance: bigint;
    fee: bigint;
  }>({
    page: "",
    isTraveling: false,
    balance: 0 as unknown as bigint,
    fee: 0 as unknown as bigint,
  });

  const onCloseWrapped = () => {
    setState((state) => {
      state.page = "";
    });
    onClose();
  };

  const travel = async () => {
    const result = await withPromiseToast(async () => {
      setState((state) => {
        state.isTraveling = true;
      });

      const selectedTokenIds = cozyPenguins
        .filter((_, i) => selected[i])
        .map((cozyPenguin) => cozyPenguin);

      const ccip =
        region === "cozyreef" ? contracts.ccipEth : contracts.ccipAvax;

      const cp =
        region === "cozyreef"
          ? contracts.cozyPenguin
          : contracts.cozyPenguinTraveler;

      const operator = await ccip.getAddress();
      const isApprovedForAll = await cp.isApprovedForAll(address, operator);

      if (!isApprovedForAll) {
        const approveTx = await cp.setApprovalForAll(operator, true);
        await approveTx.wait();
      }

      const { ethereum } = window;
      const provider = new ethers.BrowserProvider(ethereum);
      const balance = await provider.getBalance(address);
      const fee = await ccip.travelRequest(selectedTokenIds);
      setState((state) => {
        state.balance = balance;
        state.fee = fee;
      });
      const gas = await ccip.travel.estimateGas(selectedTokenIds, {
        value: fee as bigint,
      });
      const gasPrice = await (await provider.getFeeData()).gasPrice;
      const fullFee = gas * gasPrice + fee;
      setState((state) => {
        state.fee = fullFee;
      });

      if (fullFee > balance) {
        setState((state) => {
          state.page = "error";
        });
      }

      const travelTx = await ccip.travel(selectedTokenIds, {
        value: fee as bigint,
      });
      await travelTx.wait().then((receipt) => {
        const event = receipt.logs[receipt.logs.length - 1];
        if (event) {
          const messageId = event.data;
          travelTransactions.addMessageId(messageId);
        }
        setState((state) => {
          state.page = "confirmation";
        });
        onSuccessfulTravel();
      });
    }).finally(() => {
      setState((state) => {
        state.isTraveling = false;
      });
    });
    if (result === "Something bad happened") {
      setState((state) => {
        state.page = "error";
      });
    }
  };

  const warningPage = (
    <div className={classNames(styles.modalPage)}>
      <div className={classNames(styles.modalContents)}>
        <Card
          fontSize="2.5rem"
          title="Warning!"
          content={
            <>
              <div
                className={styles.penguinSelect}
                style={{ maxHeight: "180px", border: "0.2rem #c4c4c4 solid" }}
              >
                <div className={styles.grid}>
                  <div className={styles.gridContainer}>
                    {[...cozyPenguins]
                      .filter((item, i) => selected[i])
                      .map((item, i) => (
                        <GridCell
                          item={item}
                          i={i}
                          loaded={true}
                          toggleSelected={() => {}}
                          selected={false}
                          notSelectable={true}
                          imgFn={
                            region === "polarpeaks"
                              ? buildCozyPenguinExplorerTokenImage
                              : buildCozyPenguinTokenImage
                          }
                        />
                      ))}
                  </div>
                </div>
              </div>
              <div className={styles.fee}>
                <Bold color="black">
                  {" "}
                  x{
                    [...cozyPenguins].filter((item, i) => selected[i]).length
                  }{" "}
                  Cozy Penguins{" "}
                </Bold>
                <br />
                Travel fee:{" "}
                <Bold>
                  {parseFloat(fee).toFixed(5)}{" "}
                  {region === "cozyreef" ? "ETH" : "AVAX"}{" "}
                </Bold>
              </div>
            </>
          }
          color="#d56558"
          style={{
            minWidth: "80%",
          }}
        />
        <div className={classNames(styles.modalButtons)}>
          <CssButton
            onClick={travel}
            text={state.isTraveling ? "Traveling..." : "Proceed"}
            disabled={state.isTraveling}
            rounded={false}
            fontSize="2rem"
            shadowSize="4px"
            color="#b9e963"
            className={styles.nowrap}
          />
          <CssButton
            onClick={onCloseWrapped}
            text="Nevermind"
            rounded={false}
            fontSize="2rem"
            shadowSize="4px"
            color="#d56558"
            className={styles.nowrap}
          />
        </div>
        <div className={styles.confirmationPageSign}>
          <TextSign
            img={
              <img
                className={classNames(styles.sign, styles.dangerousJourney)}
                src="/assets/images/region/cozyreef/portal/speech_bubble.png"
              />
            }
            style={{
              bottom: "50%",
              transform: "translateY(65%)",
            }}
          >
            The journey may be <br /> dangerous!
          </TextSign>
        </div>
      </div>
    </div>
  );

  const confirmationPage = (
    <div className={classNames(styles.modalPage, styles.confirmationPage)}>
      <div className={classNames(styles.modalContents)}>
        <img
          style={{ width: "100%" }}
          src="/assets/images/region/cozyreef/portal/floating.gif"
        />
        <Card
          title="Your Penguins Jumped In!"
          content={
            <div
              style={{
                fontFamily: "montserrat",
                textAlign: "center",
              }}
            >
              Your Cozys may take up to<Bold> 30 minutes </Bold> to arrive on
              the other side. <br />
              <br />
              Click{" "}
              <Bold>
                <a
                  style={{
                    display: "inline",
                    color: "#3d64f6",
                    textDecoration: "none",
                  }}
                  href={buildChainlinkCCIPLink(
                    travelTransactions.messageIds[0],
                  )}
                  target="_blank"
                >
                  here
                </a>
              </Bold>{" "}
              here to see the progress.
            </div>
          }
          color="#67a2f0"
          fontSize="2rem"
          style={{
            maxWidth: "100%",
            minHeight: "200px",
          }}
        />
        <div className={classNames(styles.modalButtons)}>
          <CssButton
            onClick={() => {
              region === "polarpeaks"
                ? window.location.assign(RegionCozyReef)
                : window.location.assign(RegionPolarPeaks);
            }}
            text="Meet them there"
            rounded={false}
            fontSize="2rem"
            shadowSize="4px"
            color="#f4bc62"
            className={styles.nowrap}
          />
        </div>
        <img
          style={{ position: "relative", top: "-30px" }}
          src="/assets/images/region/cozyreef/portal/arrow.png"
        />
      </div>
    </div>
  );

  const errorPage = (
    <div className={classNames(styles.modalPage)}>
      <div className={classNames(styles.modalContents)}>
        <Card
          title="Something went wrong!"
          content={
            <div style={{ fontFamily: "montserrat" }}>
              {state.balance < state.fee ? (
                <>
                  Oops! You don't have <Bold>enough balance</Bold> to cover the
                  travel fee!
                </>
              ) : (
                <>Oops! Lets try again!</>
              )}
            </div>
          }
          color="#d56558"
          fontSize="2rem"
        />
        <div className={classNames(styles.modalButtons)}>
          <CssButton
            onClick={onCloseWrapped}
            text="Try Again!"
            rounded={false}
            fontSize="2rem"
            shadowSize="4px"
            color="#f4bc62"
            className={styles.nowrap}
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <ModalRoot
        open={open}
        onClose={onCloseWrapped}
        transparentblur
        nobg
        center={true}
        closeButton={state.page === "" && "none"}
      >
        {state.page == ""
          ? warningPage
          : state.page == "confirmation"
          ? confirmationPage
          : errorPage}
      </ModalRoot>
      {open === false ? null : state.page === "" || state.page === "error" ? (
        <Avatar
          img="/assets/images/region/cozyreef/portal/pebbles.png"
          className={classNames(styles.pebbles)}
        />
      ) : state.page === "confirmation" ? (
        <Avatar
          img="/assets/images/region/cozyreef/portal/cozy2.png"
          className={classNames(styles.happyPenguin)}
        />
      ) : null}
    </>
  );
};

export default TravelModal;
