import Section from "../../../components/Section";
import Avatar from "../../../components/Avatars";
import {
  AppStoreButton,
  AppStoreType,
  Description,
  Game,
} from "../../../components/GamePage";
import { ReactNode } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { Bold } from "../../../components/Card";

const Subtitle = "Trading Game";

const Buttons = [
  <AppStoreButton
    className={styles.appStoreMobileButtons}
    href=""
    appStore={AppStoreType.apple}
    active={false}
  />,
  <AppStoreButton
    className={styles.appStoreMobileButtons}
    href=""
    appStore={AppStoreType.android}
    active={false}
  />,
];

const CozyverseAlphaGame = ({ buttons }: { buttons: ReactNode[] }) => {
  const unityConfig = {
    loaderUrl: "/assets/build/cozyverse_alpha/Build/Build.loader.js",
    dataUrl: "/assets/build/cozyverse_alpha/Build/Build.data",
    frameworkUrl: "/assets/build/cozyverse_alpha/Build/Build.framework.js",
    codeUrl: "/assets/build/cozyverse_alpha/Build/Build.wasm",
  };

  return (
    <Game
      unityConfig={unityConfig}
      titleSrc={"/assets/images/games/cozy_verse/logo.png"}
      subtitle={Subtitle}
      buttons={buttons}
      thirdweb={true}
    />
  );
};

const CozyverseAlphaHero = ({
  className,
  playable = true,
}: {
  className?;
  playable?: boolean;
}) => {
  return (
    <Section className={classNames(styles.hero, className)}>
      <Helmet>
        <title>Cozyverse | Play </title>
      </Helmet>
      <CozyverseAlphaGame buttons={Buttons} />
    </Section>
  );
};

const CozyverseAlphaPage = () => {
  return (
    <div className={styles.root}>
      <CozyverseAlphaHero playable={true} />
      <Section className={styles.about}>
        <Description>
          <p>
            <Bold>CozyVerse</Bold> is an on-chain game on Avalanche where
            Woofys, our playable NFTs and heroes of the game, explore and build
            in their new home. The objective is to gather resources, sell them,
            or craft new materials while gaining experience. The game is using
            Chainlink VRF & Automation to drive an MMO-lite experience,
            immersing players in the snowy regions on Avalanche as they guide
            their Woofys through a captivating exploration.
          </p>
        </Description>

        <Avatar
          img="/assets/images/games/shared/ducks.png"
          hideMobile
          style={{
            left: "-15rem",
            bottom: "-10rem",
          }}
        />
        <Avatar
          img="/assets/images/games/shared/ducks.png"
          hideMobile
          style={{
            right: "-15rem",
            top: "-10rem",
          }}
        />
      </Section>
    </div>
  );
};

export { CozyverseAlphaPage, CozyverseAlphaHero };
