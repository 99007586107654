import styles from "./index.module.scss";
import Card from "./Card";
import { useContext } from "react";
import { TravelTransactionsContext } from "../../../providers/TravelTransactionsProvider";

const truncateMessageId = (msgId: string) => {
  const hashPrefix = "0x";
  const charsToShow = 10;
  return `${hashPrefix}${msgId.slice(
    hashPrefix.length,
    hashPrefix.length + charsToShow,
  )}...${msgId.slice(msgId.length - charsToShow)}`;
};

export const buildChainlinkCCIPLink = (msgId: string) => {
  return `https://ccip.chain.link/msg/${msgId}`;
};

const TransactionItem = ({
  msgId,
  onRemove,
}: {
  msgId: string;
  onRemove: () => void;
}) => {
  return (
    <div className={styles.txItem}>
      <a href={buildChainlinkCCIPLink(msgId)} target="_blank">
        {truncateMessageId(msgId)}
      </a>
      <div className={styles.redX} onClick={() => onRemove()}>
        X
      </div>
    </div>
  );
};

const TransactionHistory = () => {
  const travelTransactions = useContext(TravelTransactionsContext);

  return (
    <Card
      title="Recent Journeys"
      fontSize="2rem"
      color="#3c63f6"
      className={styles.txHistory}
      content={
        travelTransactions.messageIds.length > 0 ? (
          travelTransactions.messageIds.map((msgId) => (
            <TransactionItem
              msgId={msgId}
              onRemove={() => {
                travelTransactions.removeMessageId(msgId);
              }}
            />
          ))
        ) : (
          <div
            style={{
              fontFamily: "montserrat",
              fontSize: "0.9rem",
              whiteSpace: "nowrap",
            }}
          >
            Looks like there are no <br /> recent journeys to display!
          </div>
        )
      }
    />
  );
};

export default TransactionHistory;
