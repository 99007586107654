import classNames from "classnames";
import { ImageButton } from "../../Buttons";
import { GamesLandslide } from "constants/links";
import { AppStoreButton, AppStoreType } from "../../../components/GamePage";
import Avatar from "../../../components/Avatars";
import styles from "./index.module.scss";

const Landslide = () => {
  return (
    <div
      className={classNames(styles.cardWrapper)}
      style={{
        paddingTop: "1rem",
      }}
    >
      <img
        className={styles.logo}
        src="/assets/images/games/land_slide/logo_land_slide.png"
      />
      <div className={classNames(styles.card)}>
        <div className={styles.banner}>
          <img src="/assets/images/games/land_slide/preview_land_slide.png" />
        </div>
        <div
          className={styles.content}
          style={{
            paddingBottom: "4rem",
          }}
        >
          Adventure as Cozy and friends as you skateboard down the slopes of an
          erupting volcano! Strap on your skateboard, gather your courage, and
          prepare for an endless skater experience like no other.
        </div>
      </div>
      <ImageButton
        className={styles.button}
        img={"/assets/images/games/shared/buttons/btn_play.png"}
        onMouseDown={() => {
          window.location.assign(GamesLandslide);
        }}
      />
      <div className={styles.appStoreDesktopButtons}>
        <AppStoreButton
          appStore={AppStoreType.apple}
          href="https://apps.apple.com/us/app/landslide-by-cozy-labs/id6450430184"
        />

        <AppStoreButton
          appStore={AppStoreType.android}
          href="https://play.google.com/store/apps/details?id=xyz.cozylabs.landslide"
        />
      </div>
      <Avatar
        img="/assets/images/region/cozyreef/cards/landslide/ls.png"
        style={{
          left: "40rem",
          bottom: "0rem",
          width: "700px",
        }}
      />
    </div>
  );
};

export default Landslide;
