import styles from "./index.module.scss";
import SocialIcon from "../SocialIcon";
import { useState } from "react";
import { Bold } from "../Card";

const Footer = () => {
  const [email, setEmail] = useState("");
  return (
    <div className={styles.root}>
      <div className={styles.formWrapper}>
        <div className={styles.description}>
          <Bold color="white">Stay Connected!</Bold> <br />
          Be the first to hear about updates in the Cozyverse
        </div>
        <div id="mailchimpForm">
          <div>
            <form
              action="https://xyz.us21.list-manage.com/subscribe/post?u=dc62bc8e8d841df59a780925a&amp;id=e20ea6e1ed&amp;f_id=00abe4e6f0"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              target="_blank"
            >
              <div className={styles.form}>
                <div className={styles.emailField}>
                  <input
                    type="email"
                    name="EMAIL"
                    className={styles.text}
                    id="mce-EMAIL"
                    required={true}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Input your email here..."
                  />
                </div>
                <div
                  aria-hidden="true"
                  style={{ position: "absolute", left: "-5000px" }}
                >
                  {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                  <input
                    type="text"
                    name="b_dc62bc8e8d841df59a780925a_e20ea6e1ed"
                    tabIndex={-1}
                    value=""
                  />
                </div>
                <input
                  type="submit"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className={styles.button}
                  value="Submit"
                />
              </div>
            </form>
          </div>
          <script
            type="text/javascript"
            src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
          ></script>
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `
            (function($) {
              window.fnames = new Array();
              window.ftypes = new Array();
              fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';
              fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';
            }(jQuery));
            var $mcj = jQuery.noConflict(true);
            `,
            }}
          ></script>
        </div>
      </div>
      <div className={styles.contacts}>
        <div className={styles.logo}>
          <img src={"/assets/images/cozylabs_small.png"} />
        </div>
        <SocialIcon
          href={"https://discord.com/invite/cozyverse"}
          icon={"/assets/images/socials/discord.png"}
        />
        <SocialIcon
          href={"https://www.instagram.com/cozythepenguin/"}
          icon={"/assets/images/socials/instagram.png"}
        />
        <SocialIcon
          href={"https://twitter.com/cozylabsxyz"}
          icon={"/assets/images/socials/twitter.png"}
        />
      </div>
    </div>
  );
};

export default Footer;
