const GamesBase = "game";
const CollectionsBase = "collection";
const RegionBase = "region";

const CollectionsCozyPenguins = `/${CollectionsBase}/cozy-penguins`;
const CollectionsWoofys = `/${CollectionsBase}/woofys`;

const GamesLandslide = `/${GamesBase}/landslide`;
const GamesWaddleWars = `/${GamesBase}/waddle-wars`;
const GamesCozyverse = `/${GamesBase}/cozy-verse-demo`;
const GamesCozyverseAlpha = `/${GamesBase}/cozyverse`;
const GamesFishNFriends = `/${GamesBase}/fish-n-friends`;

const RegionCozyReef = `/${RegionBase}/cozy-reef`;
const RegionCozyReefPortal = `/${RegionBase}/cozy-reef/portal`;
const RegionPolarPeaks = `/${RegionBase}/unknown`;
const RegionPolarPeaksPortal = `/${RegionBase}/unknown/portal`;
const RegionPolarPeaksRescue = `/${RegionBase}/unknown/rescue`;
const RegionPolarPeaksCampsite = `/${RegionBase}/unknown/campsite`;

const Story = `/story`;

export {
  GamesBase,
  CollectionsBase,
  RegionBase,
  CollectionsCozyPenguins,
  CollectionsWoofys,
  GamesLandslide,
  GamesWaddleWars,
  GamesCozyverse,
  GamesCozyverseAlpha,
  GamesFishNFriends,
  RegionCozyReef,
  RegionCozyReefPortal,
  RegionPolarPeaks,
  RegionPolarPeaksPortal,
  RegionPolarPeaksRescue,
  RegionPolarPeaksCampsite,
  Story,
};
