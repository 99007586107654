import classNames from "classnames";
import { ImageButton } from "../../Buttons";
import { AppStoreButton, AppStoreType } from "../../../components/GamePage";
import { GamesWaddleWars } from "constants/links";
import Avatar from "../../../components/Avatars";
import styles from "./index.module.scss";

const WaddleWars = () => {
  return (
    <div className={classNames(styles.cardWrapper)}>
      <img
        className={styles.logo}
        src="/assets/images/games/waddle_wars/logo_waddle_wars.png"
      />
      <div className={classNames(styles.card)}>
        <div className={styles.banner}>
          <img src="/assets/images/games/waddle_wars/preview_waddle_wars.png" />
        </div>
        <div
          className={styles.content}
          style={{
            paddingBottom: "4rem",
          }}
        >
          Play as the hero penguin in a unique blend of tower defense and
          roguelike gameplay as you defend your castle from waves of cute yet
          pesky invaders.
        </div>
      </div>
      <ImageButton
        className={styles.button}
        img={"/assets/images/games/shared/buttons/btn_play.png"}
        onMouseDown={() => {
          window.location.assign(GamesWaddleWars);
        }}
      />
      <div className={styles.appStoreDesktopButtons}>
        <AppStoreButton
          appStore={AppStoreType.apple}
          href="https://apps.apple.com/us/app/waddle-wars-roguelike-defense/id6448682619"
        />

        <AppStoreButton
          appStore={AppStoreType.android}
          href="https://play.google.com/store/apps/details?id=xyz.cozylabs.waddlewars"
        />
      </div>
      <Avatar
        img="/assets/images/games/waddle_wars/ww_r.png"
        style={{
          left: "40rem",
          bottom: "0rem",
          width: "500px",
        }}
      />
    </div>
  );
};

export default WaddleWars;
