import styles from "./index.module.scss";
import classNames from "classnames";
import { CSSProperties, ReactNode } from "react";
import { blockScroll } from "../Scroll";
import { ModalRoot } from "../Modal";

export const ComicPanel = ({ children }) => {
  return <div className={styles.panel}>{children}</div>;
};

const ComicPreview = ({
  panels,
  numPreviewPanels = 3,
}: {
  panels: ReactNode[];
  numPreviewPanels?: number;
}) => {
  const mainPanel = panels[0];
  const previewPanels = panels.slice(1, numPreviewPanels);

  return (
    <div className={styles.preview}>
      <div className={styles.container}>
        {panels.map((e, index) => (
          <div
            className={styles.trail}
            style={
              {
                "--index": (index + 1).toString(),
              } as CSSProperties
            }
          >
            {e}
          </div>
        ))}
        <div className={styles.main}>{mainPanel}</div>
      </div>
    </div>
  );
};

const ComicScrollable = ({ children, extraPadding = false }) => {
  return (
    <div
      className={classNames(styles.scrollable, {
        [styles.extraPadding]: extraPadding,
      })}
    >
      {children}
    </div>
  );
};

export const ComicRoot = ({
  panels,
  showPreview = false,
  numPreviewPanels = 3,
  open,
  setOpen,
  endItems = [],
  extraPadding = false,
}: {
  panels: ReactNode[];
  showPreview?: boolean;
  numPreviewPanels?: number;
  open: boolean;
  setOpen: (boolean) => void;
  endItems?: ReactNode[];
  extraPadding?: boolean;
}) => {
  const preview = showPreview ? (
    <ComicPreview panels={panels} numPreviewPanels={numPreviewPanels} />
  ) : (
    <></>
  );

  const body = open ? (
    <ModalRoot nobg open={open} onClose={() => setOpen(false)}>
      <ComicScrollable extraPadding={extraPadding}>
        {panels}
        {endItems ? (
          <div className={styles.buttonWrapper}>{endItems}</div>
        ) : null}
      </ComicScrollable>
    </ModalRoot>
  ) : (
    preview
  );

  return (
    <div
      className={styles.root}
      onClick={() => {
        blockScroll();
        setOpen(true);
      }}
    >
      {body}
    </div>
  );
};
