import styles from "./index.module.scss";
import Portal from "components/Web3/Portal";
import Avatar from "components/Avatars";

const CozyReefPortal = () => {
  return (
    <div className={styles.root}>
      <div className={styles.contents}>
        <Portal region="cozyreef" />
      </div>
      <Avatar
        img="/assets/images/region/cozyreef/portal/rocks_l.png"
        className={styles.rocksL}
      />
      <Avatar
        img="/assets/images/region/cozyreef/portal/rocks_r.png"
        className={styles.rocksR}
      />
    </div>
  );
};

export default CozyReefPortal;
