import { CSSProperties, ReactNode } from "react";
import classNames from "classnames";
import styles from "./index.module.scss";

export enum FontSize {
  small = "small",
  medium = "medium",
  large = "large",
}

export const Card = ({
  header = null,
  footer = null,
  centerText = false,
  children,
}: {
  header?: ReactNode;
  footer?: ReactNode;
  centerText?: boolean;
  children;
}) => {
  return (
    <div
      className={classNames(styles.card, {
        [styles.header]: header,
        [styles.footer]: footer,
        [styles.centerText]: centerText,
      })}
    >
      {header}
      {children}
      {footer}
    </div>
  );
};

export const CardTitle = ({ children }: { children }) => {
  return <div className={classNames(styles.title)}>{children}</div>;
};

export const CardBlock = ({
  fontSize = FontSize.small,
  children,
}: {
  fontSize?: FontSize;
  children;
}) => {
  return (
    <div
      className={classNames(styles.block, {
        [styles.small]: fontSize === FontSize.small,
        [styles.medium]: fontSize === FontSize.medium,
        [styles.large]: fontSize === FontSize.large,
      })}
    >
      {children}
    </div>
  );
};

export const CardHeader = ({ children }) => {
  return <div className={styles.header}>{children}</div>;
};

export const CardFooter = ({ children }) => {
  return <div className={styles.footer}>{children}</div>;
};

export const Bold = ({ color, children }: { color?; children }) => {
  return (
    <span className={styles.bold} style={{ "--color": color } as CSSProperties}>
      {children}
    </span>
  );
};
