import classNames from "classnames";
import Avatar from "../../../components/Avatars";
import { CssButton } from "../../Buttons";
import styles from "./index.module.scss";
import { RegionPolarPeaksCampsite } from "constants/links";

const Campsite = () => {
  return (
    <div
      className={classNames(styles.cardWrapper)}
      style={{
        paddingTop: "3rem",
      }}
    >
      <img
        className={styles.logo}
        style={{ marginTop: "100px", width: "300px" }}
        src="/assets/images/region/polarpeaks/campsite/campsite_small.png"
      />
      <div className={styles.card}>
        <div className={styles.content}>
          Visit the Woofys and Cozy Penguins that are setting up camp and
          readying for their adventure!
        </div>
      </div>
      <div style={{ position: "relative", top: "-50px" }}>
        <CssButton
          onClick={() => {
            window.location.assign(RegionPolarPeaksCampsite);
          }}
          text={`Enter`}
        />
      </div>
      <Avatar
        img="/assets/images/region/polarpeaks/campsite/l2.png"
        style={{
          left: "50rem",
          bottom: "0rem",
          width: "500px",
        }}
      />
    </div>
  );
};

export default Campsite;
