import { useContext, useEffect, useState } from "react";
import AudioManager, { AudioGroup } from "../AudioManager";
import styles from "./index.module.scss";
import { FeatureFlagsContext } from "../../providers/FeatureFlagsProvider";
import { Button } from "../Buttons";
import classNames from "classnames";

enum VolumeState {
  HIGH = "HIGH",
  MUTE = "MUTE",
}

export const VolumeButton = ({
  className,
  muted,
}: {
  className: string;
  muted?: boolean;
}) => {
  const flags = useContext(FeatureFlagsContext);
  const [volume, setVolume] = useState(flags.defaultVolume);

  useEffect(() => {
    switch (volume) {
      case VolumeState.HIGH:
        AudioManager.instance.volume(1, AudioGroup.BG);
        break;
      case VolumeState.MUTE:
        AudioManager.instance.volume(0, AudioGroup.BG);
        break;
    }
    if (muted || JSON.parse(localStorage.getItem("regionMusic")) === false) {
      setVolume(VolumeState.MUTE);
    }
  }, [volume, muted]);

  const icon =
    volume === VolumeState.HIGH ? (
      <div className={styles.highVolume} />
    ) : (
      <div className={styles.mute} />
    );

  const handleClick = () => {
    switch (volume) {
      case VolumeState.HIGH:
        setVolume(VolumeState.MUTE);
        localStorage.setItem("regionMusic", "false");
        break;
      case VolumeState.MUTE:
        setVolume(VolumeState.HIGH);
        localStorage.setItem("regionMusic", "true");
        break;
    }
  };

  return (
    <Button className={classNames(className)} onMouseDown={handleClick} circle>
      {icon}
    </Button>
  );
};
