import styles from "./index.module.scss";

import classNames from "classnames";

const Avatar = ({
  img,
  className,
  style,
  hideMobile = false,
}: {
  img;
  className?;
  hideMobile?: boolean;
  style?;
}) => {
  return (
    <div
      className={classNames(styles.avatar, className, {
        [styles.hideMobile]: hideMobile,
      })}
      style={style}
    >
      <img src={img} />
    </div>
  );
};

export default Avatar;
