import styles from "./index.module.scss";

const SocialIcon = ({ href, icon }: { href: string; icon: string }) => {
  return (
    <div className={styles.root}>
      <a href={href} target="_blank">
        <img src={icon} />
      </a>
    </div>
  );
};

export default SocialIcon;
