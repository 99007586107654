import styles from "../index.module.scss";
import classNames from "classnames";
import * as PIXI from "pixi.js";
import { GlowFilter, DropShadowFilter } from "pixi-filters";
import Map from "../../../components/Maps";
import { useEffect, useRef, useState } from "react";
import { useImmer } from "use-immer";
import { HEIGHT, WIDTH } from "../../../components/Maps/constants";
import { ModalRoot } from "../../../components/Modal";
import {
  WaddleWars,
  Landslide,
  CozyPenguins,
  CozyLabs,
  Portal,
} from "../../../components/Maps/Cards";
import AudioManager, { AudioGroup } from "../../../components/AudioManager";
import Banner from "components/Banner";
import MobileCozyReefRegion from "../../../components/Regions/MobileCozyReefRegion";
import { VolumeButton } from "../../../components/hud";
import { ComicStoryChapter2 } from "../../../components/Comics/Stories";

class Title extends PIXI.Container {
  image: PIXI.Sprite;
  onClick: PIXI.utils.EventEmitter.ListenerFn;

  constructor(image: PIXI.Sprite, onClick: PIXI.utils.EventEmitter.ListenerFn) {
    super();

    this.image = image;
    this.onClick = AudioManager.instance.onClick(onClick);

    const glowFilter = new GlowFilter({
      color: 0xffffff,
      distance: 7,
      outerStrength: 50,
      innerStrength: 0,
      quality: 0.2,
      knockout: false,
    });

    const dropShadowFilter = new DropShadowFilter({
      distance: 7,
      rotation: 90,
      blur: 0.1,
      color: 0x000000,
      alpha: 1,
    });

    const filters = [glowFilter, dropShadowFilter];

    const initialFilters = this.image.filters ?? [];

    this.image.interactive = true;
    this.image.cursor = "pointer";
    this.image.on("pointerup", this.onClick);
    this.image.on("pointerover", () => {
      this.image.filters = [...initialFilters, ...filters];
    });
    this.image.on("pointerout", () => {
      this.image.filters = initialFilters;
    });

    this.addChild(this.image);
  }
}

const CozyReef = () => {
  const canvas = useRef<HTMLCanvasElement>();
  const [state, setState] = useImmer<{
    modal: string;
    showComic: boolean;
  }>({
    modal: "",
    showComic: false,
  });
  const root = useRef<HTMLDivElement>();

  useEffect(() => {
    function centerXRootElement() {
      if (!root.current) {
        return;
      }
      const screenWidth = window.innerWidth;
      const scrollLeft = (root.current.scrollWidth - screenWidth) / 2;
      root.current.scrollLeft = scrollLeft;
    }
    centerXRootElement();
    window.addEventListener("resize", centerXRootElement);
    return () => window.removeEventListener("resize", centerXRootElement);
  }, [root]);

  useEffect(() => {
    const initialize = async () => {
      if (!canvas.current) {
        return;
      }

      const app = new PIXI.Application({
        view: canvas.current,
        antialias: true,
        autoDensity: true,
        autoStart: false,
        sharedTicker: false,
        sharedLoader: false,
        width: WIDTH,
        height: HEIGHT,
      });

      let moveY = 105;
      const onAssetLoad = (loader, res) => {
        const bg = app.stage.addChild(
          new PIXI.TilingSprite(
            res.bg.texture,
            app.view.width,
            app.view.height,
          ),
        );
        bg.tileScale.x = 0.9;
        bg.tileScale.y = 1;

        const clouds = [];
        for (let i = 0; i < 2; i++) {
          const cloud = new PIXI.Sprite(res.cloud.texture);
          cloud.scale.x = 1.12;
          cloud.scale.y = 1;
          cloud.position.set(i * cloud.width - i * 2, 580 + moveY);
          app.stage.addChild(cloud);
          clouds.push(cloud);
        }

        app.ticker.add(() => {
          for (const cloud of clouds) {
            cloud.x -= 0.1; // The speed of the clouds
            if (cloud.x + cloud.width < 0) {
              cloud.x = app.screen.width;
            }
          }
        });

        const mountains = app.stage.addChild(
          new PIXI.Sprite(res.mountains.texture),
        );
        mountains.position.set(630, 655 + moveY);
        mountains.scale.x = 0.8;
        mountains.scale.y = 0.8;

        const volcano = app.stage.addChild(
          new PIXI.Sprite(res.volcano.texture),
        );
        volcano.position.set(710, 350 + moveY);
        volcano.scale.x = 0.8;
        volcano.scale.y = 0.8;

        const island = app.stage.addChild(new PIXI.Sprite(res.island.texture));
        island.position.set(830, 600 + moveY);
        island.scale.x = 0.8;
        island.scale.y = 0.8;

        const stars1 = app.stage.addChild(new PIXI.Sprite(res.stars1.texture));
        stars1.position.set(530, 200 + moveY);
        stars1.scale.x = 0.8;
        stars1.scale.y = 0.8;

        const stars2 = app.stage.addChild(new PIXI.Sprite(res.stars2.texture));
        stars2.position.set(730, 200 + moveY);
        stars2.scale.x = 0.8;
        stars2.scale.y = 0.8;

        const waddlewars = app.stage.addChild(
          new PIXI.Sprite(res.waddlewars.texture),
        );
        waddlewars.position.set(1760, 740 + moveY);
        waddlewars.scale.x = 0.8;
        waddlewars.scale.y = 0.8;

        const waddlewarslogo = app.stage.addChild(
          new Title(new PIXI.Sprite(res.waddlewarslogo.texture), () => {
            setState((state) => {
              state.modal = "waddlewarslogo";
            });
          }),
        );
        waddlewarslogo.position.set(1850, 900 + moveY);
        waddlewarslogo.scale.x = 0.25;
        waddlewarslogo.scale.y = 0.25;

        const rock = app.stage.addChild(new PIXI.Sprite(res.rock.texture));
        rock.position.set(1630, 900 + moveY);
        rock.scale.x = 0.8;
        rock.scale.y = 0.8;

        const cozylabs = app.stage.addChild(
          new PIXI.Sprite(res.cozylabs.texture),
        );
        cozylabs.position.set(1440, 350 + moveY);
        cozylabs.scale.x = 0.8;
        cozylabs.scale.y = 0.8;

        const cozylabslogo = app.stage.addChild(
          new Title(new PIXI.Sprite(res.cozylabslogo.texture), () => {
            setState((state) => {
              state.modal = "cozylabslogo";
            });
          }),
        );
        cozylabslogo.position.set(1560, 450 + moveY);
        cozylabslogo.scale.x = 0.8;
        cozylabslogo.scale.y = 0.8;

        const cozypenguins = app.stage.addChild(
          new PIXI.Sprite(res.cozypenguins.texture),
        );
        cozypenguins.position.set(830, 700 + moveY);
        cozypenguins.scale.x = 0.8;
        cozypenguins.scale.y = 0.8;

        const cozypenguinslogo = app.stage.addChild(
          new Title(new PIXI.Sprite(res.cozypenguinslogo.texture), () => {
            setState((state) => {
              state.modal = "cozypenguinslogo";
            });
          }),
        );
        cozypenguinslogo.position.set(860, 760 + moveY);
        cozypenguinslogo.scale.x = 0.8;
        cozypenguinslogo.scale.y = 0.8;

        const penguin = app.stage.addChild(
          new PIXI.Sprite(res.penguin.texture),
        );
        penguin.position.set(860, 920 + moveY);
        penguin.scale.x = 0.8;
        penguin.scale.y = 0.8;

        const duck = app.stage.addChild(new PIXI.Sprite(res.duck.texture));
        duck.position.set(830, 990 + moveY);
        duck.scale.x = 0.8;
        duck.scale.y = 0.8;

        const fishingfriens = app.stage.addChild(
          new PIXI.Sprite(res.fishingfriens.texture),
        );
        fishingfriens.position.set(1770, 300 + moveY);
        fishingfriens.scale.x = 0.8;
        fishingfriens.scale.y = 0.8;

        let moveDirection = 1;
        let moveSpeed = 0.07;
        app.ticker.add(() => {
          fishingfriens.y += moveDirection * moveSpeed;
          if (fishingfriens.y >= 400) {
            moveDirection = -1;
          } else if (fishingfriens.y <= 350) {
            moveDirection = 1;
          }
        });

        const landslide = app.stage.addChild(
          new PIXI.Sprite(res.landslide.texture),
        );
        landslide.position.set(820, 410 + moveY);
        landslide.scale.x = 0.8;
        landslide.scale.y = 0.8;

        const landslidelogo = app.stage.addChild(
          new Title(new PIXI.Sprite(res.landslidelogo.texture), () => {
            setState((state) => {
              state.modal = "landslidelogo";
            });
          }),
        );
        landslidelogo.position.set(930, 500 + moveY);
        landslidelogo.scale.x = 0.25;
        landslidelogo.scale.y = 0.25;

        const portal = app.stage.addChild(new PIXI.Sprite(res.portal.texture));
        portal.position.set(1200, 420 + moveY);
        portal.scale.x = 0.45;
        portal.scale.y = 0.45;

        const portallogo = app.stage.addChild(
          new Title(new PIXI.Sprite(res.portallogo.texture), () => {
            setState((state) => {
              state.modal = "portallogo";
            });
          }),
        );
        portallogo.position.set(1205, 605 + moveY);
        portallogo.scale.x = 0.8;
        portallogo.scale.y = 0.8;

        const shop = app.stage.addChild(new PIXI.Sprite(res.shop.texture));
        shop.position.set(1340, 665 + moveY);
        shop.scale.x = 0.8;
        shop.scale.y = 0.8;

        const slingshotSailors = app.stage.addChild(
          new PIXI.Sprite(res.slingshotSailors.texture),
        );
        slingshotSailors.scale.x = 0.75;
        slingshotSailors.scale.y = 0.75;
        slingshotSailors.position.set(480, 800 + moveY);
      };

      app.loader
        .add(
          "slingshotSailors",
          "/assets/images/region/cozyreef/slingshotsailors.png",
          {
            crossOrigin: true,
          },
        )
        .add("cloud", "/assets/images/region/cozyreef/cloud.png", {
          crossOrigin: true,
        })
        .add("mountains", "/assets/images/region/cozyreef/mountains.png", {
          crossOrigin: true,
        })
        .add("volcano", "/assets/images/region/cozyreef/volcano.png", {
          crossOrigin: true,
        })
        .add("stars1", "/assets/images/region/cozyreef/stars_1.png", {
          crossOrigin: true,
        })
        .add("stars2", "/assets/images/region/cozyreef/stars_2.png", {
          crossOrigin: true,
        })
        .add("waddlewars", "/assets/images/region/cozyreef/waddlewars.png", {
          crossOrigin: true,
        })
        .add(
          "waddlewarslogo",
          "/assets/images/games/waddle_wars/logo_waddle_wars.png",
          {
            crossOrigin: true,
          },
        )
        .add("rock", "/assets/images/region/cozyreef/rock.png", {
          crossOrigin: true,
        })
        .add("penguin", "/assets/images/region/cozyreef/penguin.png", {
          crossOrigin: true,
        })
        .add("cozylabs", "/assets/images/region/cozyreef/cozylabs.png", {
          crossOrigin: true,
        })
        .add(
          "cozylabslogo",
          "/assets/images/region/cozyreef/logo_cozylabs.png",
          {
            crossOrigin: true,
          },
        )
        .add(
          "cozypenguins",
          "/assets/images/region/cozyreef/cozypenguins.png",
          {
            crossOrigin: true,
          },
        )
        .add(
          "cozypenguinslogo",
          "/assets/images/collection/cozypenguins/logo_cozypenguins.png",
          {
            crossOrigin: true,
          },
        )
        .add("duck", "/assets/images/region/cozyreef/duck.png", {
          crossOrigin: true,
        })
        .add(
          "fishingfriens",
          "/assets/images/region/cozyreef/fishingfriens.png",
          {
            crossOrigin: true,
          },
        )
        .add("landslide", "/assets/images/region/cozyreef/landslide.png", {
          crossOrigin: true,
        })
        .add(
          "landslidelogo",
          "/assets/images/games/land_slide/logo_land_slide.png",
          {
            crossOrigin: true,
          },
        )
        .add("portal", "/assets/images/region/cozyreef/portal.png", {
          crossOrigin: true,
        })
        .add("portallogo", "/assets/images/region/cozyreef/logo_portal.png", {
          crossOrigin: true,
        })
        .add("shop", "/assets/images/region/cozyreef/shop.png", {
          crossOrigin: true,
        });

      app.loader
        .add("bg", "/assets/images/region/cozyreef/background/bg.png", {
          crossOrigin: true,
        })
        .add("island", "/assets/images/region/cozyreef/island.png", {
          crossOrigin: true,
        })
        .load(onAssetLoad);

      app.start();
    };
    initialize();
    return () => {
      PIXI.utils.clearTextureCache();
    };
  }, [canvas]);

  const soundRef = useRef<HTMLDivElement>();
  const [muted, setMuted] = useState(false);

  useEffect(() => {
    const howl = AudioManager.instance.play(
      {
        src: "/assets/sounds/region/cozyreef/map_cozyreef.mp3",
        volume: 0.35,
        loop: true,
      },
      AudioGroup.BG,
    );

    const sound = soundRef.current;
    if (sound) {
      const computedStyle = getComputedStyle(sound);
      const displayValue = computedStyle.getPropertyValue("display");
      if (displayValue === "none") {
        setMuted(true);
      }
    }

    return () => {
      howl.stop();
    };
  }, []);

  return (
    <>
      <div className={classNames(styles.root, styles.cozyReef)} ref={root}>
        <div className={styles.body}>
          <Map>
            <canvas ref={canvas} />
            {state.modal === "waddlewarslogo" && (
              <ModalRoot
                nobg
                transparentblur
                scrollable={false}
                open={true}
                closeButton="arrow"
                onClose={() => {
                  setState((state) => {
                    state.modal = null;
                  });
                }}
              >
                <WaddleWars />
              </ModalRoot>
            )}
            {state.modal === "landslidelogo" && (
              <ModalRoot
                nobg
                transparentblur
                scrollable={false}
                open={true}
                closeButton="arrow"
                onClose={() => {
                  setState((state) => {
                    state.modal = null;
                  });
                }}
              >
                <Landslide />
              </ModalRoot>
            )}
            {state.modal === "cozypenguinslogo" && (
              <ModalRoot
                nobg
                transparentblur
                scrollable={false}
                open={true}
                closeButton="arrow"
                onClose={() => {
                  setState((state) => {
                    state.modal = null;
                  });
                }}
              >
                <CozyPenguins />
              </ModalRoot>
            )}
            {state.modal === "cozylabslogo" && (
              <ModalRoot
                nobg
                transparentblur
                scrollable={false}
                open={true}
                closeButton="arrow"
                onClose={() => {
                  setState((state) => {
                    state.modal = null;
                  });
                }}
              >
                <CozyLabs
                  setOpen={(open) => {
                    setState((state) => {
                      state.showComic = open;
                    });
                  }}
                />
              </ModalRoot>
            )}
            {state.modal === "portallogo" && (
              <ModalRoot
                nobg
                transparentblur
                scrollable={false}
                open={true}
                closeButton="arrow"
                onClose={() => {
                  setState((state) => {
                    state.modal = null;
                  });
                }}
              >
                <Portal region={"cozyreef"} />
              </ModalRoot>
            )}
            {!state.modal && (
              <div className={styles.bannerWrapper}>
                <Banner text="Cozy Reef" color="red" />
              </div>
            )}
          </Map>
        </div>
        {/*TODO: had to add the comic here to get the relative positioning to work, probably a better solution to use a provider*/}
        <ComicStoryChapter2
          open={state.showComic}
          setOpen={(open) => {
            setState((state) => {
              state.showComic = open;
            });
          }}
        />
        <MobileCozyReefRegion
          logoSrc="/assets/images/region/cozyreef/logo_cozyreef.png"
          setOpen={(open) => {
            setState((state) => {
              state.showComic = open;
            });
          }}
        />
      </div>
      <div
        ref={soundRef}
        className={classNames(styles.sound, {
          [styles.hide]: state.modal,
        })}
      >
        <VolumeButton className={styles.volume} muted={muted} />
      </div>
    </>
  );
};

export default CozyReef;
