import styles from "./index.module.scss";
import classNames from "classnames";

export const CTA = ({ footer, children }: { footer?; children }) => {
  return (
    <div
      className={classNames(styles.root, {
        [styles.footer]: footer,
      })}
    >
      {children}
      {footer}
    </div>
  );
};

export const CTATitle = ({ children }) => {
  return <div className={styles.title}>{children}</div>;
};

export const CTASubtitle = ({ children }) => {
  return <div className={styles.subtitle}>{children}</div>;
};

export const CTAFooter = ({ children }) => {
  return <div className={styles.footer}>{children}</div>;
};
