const safeDocument: Document = document;
const html = safeDocument.documentElement;
const { body } = safeDocument;

export const blockScroll = () => {
  html.style.overflow = "hidden";
  body.style.overflow = "hidden";
};

export const allowScroll = () => {
  html.style.overflow = "";
  body.style.overflow = "";
};
