import styles from "./index.module.scss";
import { ImageButton } from "../Buttons";
import { useImmer } from "use-immer";
import { isMobile } from "react-device-detect";

export const CarouselItem = ({ children }) => {
  return <div className={styles.item}>{children}</div>;
};

export const Carousel = ({ display, children }) => {
  const [state, setState] = useImmer<{
    displayIndices: number[];
  }>({
    displayIndices: Array.from(Array(isMobile ? 1 : display).keys()),
  });

  const decrement = () => {
    setState((state) => {
      const rhs = state.displayIndices[state.displayIndices.length - 1];
      state.displayIndices = [
        ...state.displayIndices.slice(1),
        rhs >= display - 1 ? 0 : rhs + 1,
      ];
    });
  };

  const increment = () => {
    setState((state) => {
      const lhs = state.displayIndices[0];
      state.displayIndices = [
        lhs <= 0 ? children.length - 1 : lhs - 1,
        ...state.displayIndices.slice(0, state.displayIndices.length - 1),
      ];
    });
  };

  return (
    <div className={styles.carousel}>
      <ImageButton
        img="/assets/images/home/2/left arrow.png"
        className={styles.button}
        disabled={false}
        onMouseDown={() => decrement()}
      />
      <div className={styles.items}>
        <>{state.displayIndices.map((i) => children[i])}</>
      </div>
      <ImageButton
        img="/assets/images/home/2/right arrow.png"
        className={styles.button}
        disabled={false}
        onMouseDown={() => increment()}
      />
    </div>
  );
};
