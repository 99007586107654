import { createContext } from "react";

type FeatureFlagsContextValue = {
  disableGame: boolean;
  defaultVolume: string;
};

export const FeatureFlagsContext = createContext<FeatureFlagsContextValue>({
  disableGame: false,
  defaultVolume: "HIGH",
});

const FeatureFlagsProvider = ({ children }) => {
  const context = {
    disableGame: process.env.NEXT_PUBLIC_FEATURE_DISABLE_GAME === "true",
    defaultVolume: process.env.NEXT_PUBLIC_FEATURE_VOLUME || "HIGH",
  };

  return (
    <FeatureFlagsContext.Provider value={context}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export default FeatureFlagsProvider;
