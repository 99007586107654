import styles from "./index.module.scss";
import classNames from "classnames";
import { useImmer } from "use-immer";
import {
  ComicStoryChapter1,
  ComicStoryChapter2,
  ComicStoryChapter3,
} from "../../components/Comics/Stories";
import Avatar from "../../components/Avatars";
import React from "react";

const chapters = [
  {
    title: "Ch. 4 Coming Soon",
    releaseDate: "TBD",
    image: "assets/images/story/locked.png",
    available: false,
    chapter: null,
  },
  {
    title: "Ch. 3 - The Escape",
    releaseDate: "Nov 1, 2023",
    image: "assets/images/story/3.png",
    available: true,
    chapter: ComicStoryChapter3,
  },
  {
    title: "Ch. 2 - A Portal Opens",
    releaseDate: "Oct 1, 2023",
    image: "assets/images/story/2.png",
    available: true,
    chapter: ComicStoryChapter2,
  },
  {
    title: "Ch. 1 - Cozy Penguins: Origin",
    releaseDate: "Sep 1, 2023",
    image: "assets/images/story/1.png",
    available: true,
    chapter: ComicStoryChapter1,
  },
];

const ChapterCard = ({
  title,
  image,
  releaseDate,
  available,
  onOpen,
}: {
  title: string;
  image: string;
  releaseDate: string;
  available: boolean;
  onOpen: () => void;
}) => {
  return (
    <div
      className={classNames(styles.chapterCard, {
        [styles.active]: available,
      })}
      onClick={onOpen}
    >
      <div className={styles.chapterCardImage}>
        <img src={image} />
      </div>
      <div className={styles.chapterCardContent}>
        <div className={styles.chapterCardTitle}>{title}</div>
        <div className={styles.chapterCardReleaseDate}>{releaseDate}</div>
      </div>
    </div>
  );
};

const TitleCard = ({ content }: { content: string }) => {
  return (
    <div className={styles.titleCard}>
      <div className={styles.titleCardTitle}>
        <img src="/assets/images/story/story.png" />
      </div>
      <div className={styles.titleCardContent}>{content}</div>
    </div>
  );
};

const Story = () => {
  const [state, setState] = useImmer<{
    selectedComicIndex: number;
  }>({
    selectedComicIndex: -1,
  });

  return (
    <div className={styles.root}>
      <div className={styles.bg}>
        <div className={styles.banner}>
          <Avatar
            img="/assets/images/story/penguin.png"
            className={styles.penguin}
          />
          <Avatar img="/assets/images/story/duck.png" className={styles.duck} />
          <Avatar
            img="/assets/images/story/snow_f.png"
            className={styles.snowfront}
          />
        </div>
        <div className={styles.tile} />
      </div>
      <div className={styles.contents}>
        <TitleCard content="Follow along Cozy and friends as they adventure in the Cozyverse!" />
        <div className={styles.chapters}>
          {chapters.map((chapter, i) => (
            <ChapterCard
              title={chapter.title}
              image={chapter.image}
              releaseDate={chapter.releaseDate}
              available={chapter.available}
              onOpen={() => {
                setState((state) => {
                  state.selectedComicIndex = i;
                });
              }}
            />
          ))}
        </div>
      </div>
      {state.selectedComicIndex !== -1 &&
      chapters[state.selectedComicIndex].available &&
      chapters[state.selectedComicIndex].chapter
        ? chapters[state.selectedComicIndex].chapter({
            open: state.selectedComicIndex !== -1,
            setOpen: (open) => {
              setState((state) => {
                state.selectedComicIndex = -1;
              });
            },
          })
        : null}
    </div>
  );
};

export default Story;
