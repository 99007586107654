import styles from "./index.module.scss";
import Section from "../../components/Section";
import { Carousel, CarouselItem } from "../../components/Carousel";
import Avatar from "../../components/Avatars";
import {
  CollectionsCozyPenguins,
  CollectionsWoofys,
  GamesLandslide,
  GamesWaddleWars,
  RegionCozyReef,
  RegionPolarPeaks,
  RegionPolarPeaksCampsite,
} from "../../constants/links";
import React from "react";
import { useState } from "react";
import { CTA, CTAFooter, CTASubtitle, CTATitle } from "../../components/CTA";

import { CssButton } from "../../components/Buttons";

const snowflakes = [];
for (let i = 0; i < 50; i++) {
  snowflakes.push(<div key={i} className={styles.snowflake}></div>);
}

const Home = () => {
  const [email, setEmail] = useState("");
  return (
    <div className={styles.root}>
      <Section className={styles.section1}>
        <div className={styles.content}>
          <Avatar
            img="/assets/images/home/fish-n-friends/logo_fishin_friends.png"
            className={styles.logoFishNFriends}
          />
          <div className={styles.comingSoon}>Coming soon!</div>
          <form
            action="https://xyz.us21.list-manage.com/subscribe/post?u=dc62bc8e8d841df59a780925a&amp;id=e20ea6e1ed&amp;f_id=00abe4e6f0"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            target="_blank"
          >
            <div className={styles.sign}>
              <input
                type="email"
                name="EMAIL"
                id="mce-EMAIL"
                required={true}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={styles.panelInput}
                placeholder="enter your email"
              />
              <div
                aria-hidden="true"
                style={{ position: "absolute", left: "-5000px" }}
              >
                {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                <input
                  type="text"
                  name="b_dc62bc8e8d841df59a780925a_e20ea6e1ed"
                  tabIndex={-1}
                  value=""
                />
              </div>
            </div>
            <div className={styles.breathingButton}>
              <CssButton
                className={styles.signUpButton}
                onClick={(e) => {
                  e.preventDefault();
                  (
                    document.getElementById(
                      "mc-embedded-subscribe-form",
                    ) as HTMLFormElement
                  )?.submit();
                }}
                text="Sign up!"
                color="#42e6e8"
              />
            </div>
          </form>
        </div>
        <Avatar
          img="/assets/images/home/fish-n-friends/cozy.png"
          className={styles.rescue}
        />
        <Avatar
          img="/assets/images/home/fish-n-friends/bottom.png"
          className={styles.snowfront}
        />
      </Section>
      <Section className={styles.section2}>
        <Carousel display={3}>
          <CarouselItem>
            <a href={CollectionsCozyPenguins}>
              <img
                src="/assets/images/home/2/cozy penguins tile.png"
                className={styles.carouselImg}
              />
            </a>
          </CarouselItem>
          <CarouselItem>
            <a href={GamesLandslide}>
              <img
                src="/assets/images/home/2/landslide tile.png"
                className={styles.carouselImg}
              />
            </a>
          </CarouselItem>
          <CarouselItem>
            <a href={GamesWaddleWars}>
              <img
                src="/assets/images/home/2/waddlewars.png"
                className={styles.carouselImg}
              />
            </a>
          </CarouselItem>
        </Carousel>
      </Section>
      <Section className={styles.section3}>
        <CTA
          footer={
            <CTAFooter>
              <CssButton
                onClick={() => {
                  window.location.assign(GamesLandslide);
                }}
                text="Play now"
              />
            </CTAFooter>
          }
        >
          <CTATitle>Play</CTATitle>
          <CTASubtitle>
            Click and go. Go on an adventure with Cozy and friends on browser or
            mobile.
          </CTASubtitle>
        </CTA>
        <Avatar
          img="/assets/images/home/3/Pengu Skater.png"
          className={styles.skater}
          style={{}}
        />
      </Section>
      <Section className={styles.section4}>
        <CTA
          footer={
            <CTAFooter>
              <div className={styles.buttonWrapper}>
                <CssButton
                  className={styles.button}
                  onClick={() => {
                    window.location.assign(RegionCozyReef);
                  }}
                  text="Cozy Reef"
                />
                <CssButton
                  className={styles.button}
                  onClick={() => {
                    window.location.assign(RegionPolarPeaks);
                  }}
                  color="#42e6e8"
                  text="Polar Peaks"
                />
              </div>
            </CTAFooter>
          }
        >
          <CTATitle>Explore</CTATitle>
          <CTASubtitle>
            Visit the regions of the Cozyverse, each with unique features to
            explore.
          </CTASubtitle>
        </CTA>
        <Avatar
          img="/assets/images/home/4/explorer pengu.png"
          className={styles.explorer}
        />
      </Section>
      <Section className={styles.section5}>
        <CTA
          footer={
            <CTAFooter>
              <CssButton
                onClick={() => {
                  window.location.assign(CollectionsWoofys);
                }}
                text="Learn More"
                color="#42e6e8"
              />
            </CTAFooter>
          }
        >
          <CTATitle>Collect</CTATitle>
          <CTASubtitle>
            Dive into the Cozyverse by acquiring one of 5,555 distinctive
            Woofys.
          </CTASubtitle>
        </CTA>
        <Avatar
          img="/assets/images/collection/woofys/1/ace_big.png"
          className={styles.aceBig}
        />
        <Avatar
          img="/assets/images/collection/woofys/1/mining_axe1.png"
          className={styles.iceAxe}
        />
        <Avatar
          img="/assets/images/collection/woofys/1/snow_f.png"
          className={styles.snowfront}
        />
        <Avatar
          img="/assets/images/collection/woofys/1/snow_b.png"
          className={styles.snowbehind}
        />
      </Section>
      <Section className={styles.section6}>
        <CTA
          footer={
            <CTAFooter>
              <CssButton
                onClick={() => {
                  window.location.assign(CollectionsCozyPenguins);
                }}
                text="Learn More"
              />
            </CTAFooter>
          }
        >
          <CTATitle>Collect</CTATitle>
          <CTASubtitle>
            Adopt one of 10,000 unique Cozy Penguins and play along as they
            journey through the Cozyverse.
          </CTASubtitle>
        </CTA>
        <Avatar
          img="/assets/images/home/6/Left Cozy Group.png"
          hideMobile
          style={{
            left: "-16rem",
            bottom: "-10rem",
            transform: "scale(80%)",
          }}
        />
        <Avatar
          img="/assets/images/home/6/Right Cozy Group.png"
          hideMobile
          style={{
            right: "-10rem",
            bottom: "-8rem",
            transform: "scale(80%)",
          }}
        />
        <Avatar
          img="/assets/images/home/6/Gasha.png"
          className={styles.gasha}
        />
      </Section>
    </div>
  );
};

export default Home;
