import { CSSProperties, useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import styles from "./index.module.scss";
import { ModalBody, ModalRoot } from "../Modal";
import { getWoofyMetadata, WoofyMetadata } from "../../utils/web3";
import { ContractsContext } from "../../providers/ContractsProvider";
import TraitToRarity from "../../metadata/woofys/rarity.json";
import classNames from "classnames";

export type Rarity = "common" | "uncommon" | "rare" | "legendary" | "unknown";

export const RarityToColor: { [rarity in Rarity]: string } = {
  common: "#D2D8E0",
  uncommon: "#A4FC80",
  rare: "#89CDFA",
  legendary: "#F9DA5C",
  unknown: "#D2D8E0",
};

const WoofyTraitsModal = ({
  open,
  onClose,
  woofyId,
}: {
  open: boolean;
  onClose: () => void;
  woofyId: number;
}) => {
  return (
    <ModalRoot
      open={open}
      onClose={onClose}
      nobg
      transparentblur
      scrollable={false}
      center
    >
      <ModalBody>
        {woofyId !== -1 ? <ModalCard woofyId={woofyId} /> : null}
      </ModalBody>
    </ModalRoot>
  );
};

const Trait = ({ type, value }: { type: string; value: string }) => {
  const color = RarityToColor[TraitToRarity[value] || "unknown"] || "grey";
  return (
    <div className={styles.trait}>
      <div className={styles.traitContents}>
        <span className={styles.traitType}>{type}:</span>
        <span
          className={styles.traitValue}
          style={
            {
              "--color": color,
            } as CSSProperties
          }
        >
          {value}
        </span>
      </div>
      <div
        className={styles.traitIcon}
        style={
          {
            "--color": color,
          } as CSSProperties
        }
      />
    </div>
  );
};

const Portrait = ({
  name,
  pack,
  woofyId,
  isPackLeader,
  src,
}: {
  name: string;
  pack: string;
  woofyId: number;
  isPackLeader: boolean;
  src: string;
}) => {
  const packTrait = isPackLeader ? (
    <span
      className={classNames(
        styles.portraitTraitValue,
        styles.packLeaderTraitValue,
      )}
    >
      Pack Leader
    </span>
  ) : (
    <>
      <span className={styles.portraitTraitName}>Pack:</span>
      <span className={styles.portraitTraitValue}>{pack}</span>
    </>
  );

  return (
    <div
      className={classNames(styles.portraitCard, {
        [styles.packLeader]: isPackLeader,
      })}
    >
      <div className={styles.portraitImage}>
        <img src={src} />
      </div>
      <div className={styles.portraitInfo}>
        <div className={styles.portraitName}>{name}</div>
        <div className={styles.portraitTraits}>
          <div className={styles.portraitTrait}>{packTrait}</div>
          <div className={styles.portraitTrait}>
            <span className={styles.portraitTraitName}>Token ID:</span>
            <span className={styles.portraitTraitValue}>{woofyId}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const ModalCard = ({ woofyId }: { woofyId: number }) => {
  const contracts = useContext(ContractsContext);

  const [state, setState] = useImmer<{
    loaded: boolean;
    metadata?: WoofyMetadata;
  }>({
    loaded: false,
    metadata: null,
  });

  const initialize = async () => {
    const metadata = await getWoofyMetadata(contracts.woofy, woofyId);
    setState((state) => {
      state.loaded = true;
      state.metadata = metadata;
    });
  };

  useEffect(() => {
    if (contracts.loaded && woofyId !== -1) {
      setState((state) => {
        state.loaded = false;
        state.metadata = null;
      });
      initialize();
    }
  }, [woofyId]);

  useEffect(() => {
    if (contracts.loaded && woofyId !== -1 && !state.loaded) {
      initialize();
    }
  });

  return state.loaded ? (
    <div className={styles.root}>
      <Portrait
        src={state.metadata.image}
        name={state.metadata.name}
        pack={state.metadata.pack}
        isPackLeader={state.metadata.isPackLeader}
        woofyId={woofyId}
      />
      {!state.metadata.isPackLeader ? (
        <div className={styles.traits}>
          {state.metadata.attributes.map((attribute) => (
            <Trait type={attribute.traitType} value={attribute.value} />
          ))}
        </div>
      ) : null}
    </div>
  ) : null;
};

export default WoofyTraitsModal;
