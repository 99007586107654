import styles from "./index.module.scss";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useImmer } from "use-immer";
import {
  buildCozyPenguinExplorerTokenImage,
  buildCozyPenguinTokenImage,
  buildWoofyTokenImage,
  getWoofyMetadataId,
} from "../../utils/web3";
import { useContext } from "react";
import { ContractsContext } from "../../providers/ContractsProvider";

export const GridCell = ({
  item,
  i,
  empty = false,
  selected,
  toggleSelected = () => {},
  loaded,
  notSelectable = false,
  imgFn,
  title,
}: {
  item?: number;
  i: number;
  empty?: boolean;
  selected: boolean;
  toggleSelected?: () => void;
  loaded: boolean;
  notSelectable?: boolean;
  imgFn?: (tokenId: number) => string;
  title?: string;
}) => {
  const isSelectable = !empty && loaded && loaded;
  const contracts = useContext(ContractsContext);
  const [metadataId, setMetadataId] = useState(0);

  useEffect(() => {
    if (title?.includes("woofys") && item) {
      const woofyMetadataId = async () => {
        setMetadataId(await getWoofyMetadataId(contracts.woofy, item));
      };
      woofyMetadataId();
    }
  }, []);

  return (
    <div
      key={`cell-${i}`}
      className={classNames(styles.cell, {
        [styles.empty]: empty,
        [styles.selected]: selected && !notSelectable,
        [styles.selectable]: isSelectable && !notSelectable,
        [styles.loading]: !loaded,
      })}
      onClick={() => {
        if (isSelectable && !empty) {
          toggleSelected();
        }
      }}
      style={
        title === "woofys" || title === "woofys image viewer"
          ? { backgroundColor: "#f4f1f0" }
          : { backgroundColor: "#3d64f6" }
      }
    >
      <div className={styles.cellContainer}>
        <img
          src={
            loaded
              ? empty
                ? title == "woofys" || title === "woofys image viewer"
                  ? "/assets/images/region/polarpeaks/campsite/btn_woofy0.png"
                  : "/assets/images/icons/icon_head.png"
                : title == "woofys" || title === "woofys image viewer"
                ? metadataId == 0
                  ? "/assets/images/icons/loading.gif"
                  : imgFn(metadataId)
                : imgFn(item)
              : "/assets/images/icons/loading.gif"
          }
        />
      </div>
      <div className={styles.id}>#{item}</div>
    </div>
  );
};

const Grid = ({
  title,
  balance,
  gridItems,
  selected,
  toggleSelected,
  loaded,
}: {
  title?: string;
  balance?: number;
  gridItems: number[];
  selected: boolean[];
  toggleSelected?: (i) => void;
  loaded?: boolean;
}) => {
  const [state, setState] = useImmer<{
    numColumns: number;
  }>({
    numColumns: 0,
  });

  const gridRef = useRef(null);

  const getNumColumns = () => {
    const current = gridRef.current;
    const computedStyles = window.getComputedStyle(current);
    return computedStyles.getPropertyValue("grid-template-columns").split(" ")
      .length;
  };

  const onResize = () => {
    setState((state) => {
      state.numColumns = getNumColumns();
    });
  };

  useEffect(() => {
    onResize();
  }, [gridItems]);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  const mod = gridItems.length % state.numColumns;

  const numPaddedCells =
    gridItems.length <= state.numColumns * state.numColumns
      ? state.numColumns * state.numColumns - gridItems.length
      : mod > 0
      ? state.numColumns - mod
      : 0;

  const loadingBody = (
    <>
      {Array.from({ length: balance }).map((item, i) => (
        <GridCell
          i={i}
          loaded={false}
          selected={false}
          toggleSelected={() => {}}
        />
      ))}
    </>
  );

  const loadedBody = (
    <>
      {[...gridItems]
        .sort((a, b) => a - b)
        .map((item, i) => (
          <GridCell
            title={title}
            item={item}
            i={i}
            loaded={loaded}
            selected={selected[i]}
            toggleSelected={() => toggleSelected(i)}
            notSelectable={
              title === "polarpeaksRescueIneligible" ? true : false
            }
            imgFn={
              title.includes("woofys")
                ? buildWoofyTokenImage
                : title.includes("polarpeaks")
                ? buildCozyPenguinExplorerTokenImage
                : buildCozyPenguinTokenImage
            }
          />
        ))}
      {Array.from({ length: numPaddedCells }).map((_, i) => (
        <GridCell
          title={title}
          item={null}
          i={i + gridItems.length}
          empty
          loaded={loaded}
          selected={false}
        />
      ))}
    </>
  );

  return (
    <div className={styles.grid}>
      <div className={styles.gridContainer} ref={gridRef}>
        {loaded ? loadedBody : loadingBody}
      </div>
    </div>
  );
};

export default Grid;
