import styles from "./index.module.scss";
import classNames from "classnames";
import { CssButton } from "../../Buttons";
import { useState, useContext } from "react";
import { BigNumberish } from "ethers";
import {
  buildCozyPenguinExplorerTokenImage,
  buildWoofyTokenImage,
  getWoofyMetadataId,
} from "../../../utils/web3";
import { ContractsContext } from "../../../providers/ContractsProvider";

const StatusChecker = ({ title }: { title: string }) => {
  const contracts = useContext(ContractsContext);
  const [itemToCheck, setItemToCheck] = useState(0);
  const [metadataId, setMetadataId] = useState(0);
  const [status, setStatus] = useState<string>("");
  const [error, setError] = useState("");

  const checkCozyStatus = async () => {
    const inputElement = document.getElementById(
      "searchInput",
    ) as HTMLInputElement;

    const checkIfWoofyClaimed = async (
      cozyPenguinId: BigNumberish,
    ): Promise<boolean[]> => {
      return await contracts.woofy.woofiesClaimedByCozyTokens([cozyPenguinId]);
    };
    if (inputElement) {
      const inputValue = Number((inputElement as HTMLInputElement).value);
      if (
        !isNaN(inputValue) &&
        inputValue >= 1 &&
        inputValue <= 10000 &&
        inputValue % 1 === 0
      ) {
        setError("");
        setItemToCheck(inputValue);
        const isWoofyClaimed = await checkIfWoofyClaimed(inputValue);
        if (isWoofyClaimed[0] === false) {
          setStatus("false");
        } else {
          setStatus("true");
        }
      } else {
        inputElement.value = "";
        setItemToCheck(0);
        setError("ID should be between 1 and 10 000!");
      }
    }
  };
  const checkWoofyStatus = async () => {
    const inputElement = document.getElementById(
      "searchInput",
    ) as HTMLInputElement;

    if (inputElement) {
      const inputValue = Number((inputElement as HTMLInputElement).value);
      if (
        !isNaN(inputValue) &&
        inputValue >= 1 &&
        inputValue <= 5555 &&
        inputValue % 1 === 0
      ) {
        setError("");
        setItemToCheck(inputValue);
        const metadataId = await getWoofyMetadataId(
          contracts.woofy,
          inputValue,
        );
        setMetadataId(metadataId);
        const isWoofyNotRevealed = metadataId === 0;
        if (isWoofyNotRevealed) {
          setStatus("true");
        } else {
          setStatus("false");
        }
      } else {
        inputElement.value = "";
        setItemToCheck(0);
        setError("ID should be between 1 and 5555!");
      }
    }
  };

  return (
    <>
      <div className={styles.checkWrapper}>
        <input
          type="number"
          className={styles.searchField}
          id="searchInput"
          placeholder={
            error
              ? error
              : itemToCheck
              ? `${itemToCheck}`
              : title === "cozy penguins"
              ? "Type Cozy Penguin ID here..."
              : "Type Woofy ID here..."
          }
        />

        <div
          className={styles.itemImage}
          style={
            title === "cozy penguins"
              ? { backgroundColor: "#3d64f6" }
              : { backgroundColor: "#f4f1f0" }
          }
        >
          <img
            alt=""
            src={
              itemToCheck === 0 && title === "cozy penguins"
                ? "/assets/images/region/polarpeaks/rescue/id_cozy.png"
                : itemToCheck === 0 && title === "woofys"
                ? "/assets/images/region/polarpeaks/campsite/btn_woofy0.png"
                : title === "woofys" && status === "true"
                ? "/assets/images/region/polarpeaks/campsite/btn_woofy0.png"
                : title === "woofys" && status === "false"
                ? buildWoofyTokenImage(metadataId)
                : buildCozyPenguinExplorerTokenImage(itemToCheck)
            }
            style={
              itemToCheck === 0 && title === "cozy penguins"
                ? { width: "50%", height: "30%" }
                : null
            }
          />
        </div>
        <div className={styles.statusWrapper}>
          <div className={styles.statusTitle}>Status</div>
          <div className={styles.status}>
            {itemToCheck === 0 || status === "" ? (
              <>
                <div className={styles.text}>
                  Type a <br />
                  {title === "cozy penguins" ? "Cozy ID" : "Woofy ID"}
                </div>
                <div className={classNames(styles.icon, styles.empty)}>/</div>
              </>
            ) : status === "false" ? (
              title === "cozy penguins" ? (
                <>
                  <div className={styles.text}>
                    Never <br />
                    rescued
                  </div>
                  <div className={classNames(styles.icon, styles.check)}>
                    ✔
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={styles.text}
                    style={{ paddingBottom: "0.5rem" }}
                  >
                    Revealed
                  </div>
                  <div className={classNames(styles.icon, styles.check)}>
                    ✔
                  </div>
                </>
              )
            ) : title === "cozy penguins" ? (
              <>
                <div className={styles.text}>
                  Previously <br />
                  rescued
                </div>
                <div className={classNames(styles.icon, styles.x)}>x</div>
              </>
            ) : (
              <>
                <div className={styles.text}>
                  Not <br />
                  revealed
                </div>
                <div className={classNames(styles.icon, styles.x)}>x</div>
              </>
            )}
          </div>
        </div>
      </div>
      <CssButton
        onClick={title === "cozy penguins" ? checkCozyStatus : checkWoofyStatus}
        text="check"
        rounded={false}
        fontSize="2rem"
        color="#42e6e8"
        className={styles.button}
        shadowSize="4px"
      />
    </>
  );
};
export default StatusChecker;
