import styles from "./index.module.scss";
import classNames from "classnames";
import Avatar from "../../Avatars";
import { Button } from "../../Buttons";
import { ReactNode } from "react";
import { RegionPolarPeaks } from "../../../constants/links";

const TabWrapper = ({
  title,
  tab,
  setTab,
  children,
}: {
  title: string;
  tab: number;
  setTab: (string) => void;
  children: ReactNode;
}) => {
  return (
    <div className={styles.tabWrapper}>
      <div className={styles.tabButtons}>
        <div className={styles.backButtonWrapper}>
          <Button
            className={styles.backButton}
            onMouseDown={() => {
              window.location.assign(RegionPolarPeaks);
            }}
          >
            <div></div>
          </Button>
        </div>
        <div
          className={classNames(styles.tabButton, {
            [styles.active]: tab === 1,
            [styles.capsules]: title === "campsite",
            [styles.cozy]: title === "rescue",
          })}
          onClick={() => setTab(1)}
        />
        <div
          className={classNames(styles.tabButton, {
            [styles.active]: tab === 2,
            [styles.woofys]: title === "campsite",
            [styles.explorer]: title === "rescue",
          })}
          onClick={() => setTab(2)}
        />
        <div
          className={classNames(styles.tabButton, {
            [styles.active]: tab === 3,
            [styles.checker]: title === "campsite",
            [styles.find]: title === "rescue",
          })}
          onClick={() => setTab(3)}
        />
        <div />
      </div>
      {children}
    </div>
  );
};
export default TabWrapper;
