import styles from "./index.module.scss";
import classNames from "classnames";

const TextSign = ({
  img,
  children,
  style,
  className,
}: {
  img;
  children;
  style?;
  className?;
}) => {
  return (
    <div className={classNames(styles.textSign, className)}>
      {img}
      <div className={styles.text} style={style}>
        {children}
      </div>
    </div>
  );
};

export default TextSign;
