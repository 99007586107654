import { Bold } from "../../../components/Card";
import styles from "./index.module.scss";
import { CssButton } from "../../../components/Buttons";
import classNames from "classnames";
import TextSign from "./TextSign";
import { ContractsContext } from "../../../providers/ContractsProvider";
import { useContext } from "react";
import { ethers } from "ethers";

const WarpGate = ({
  region,
  loaded,
  setModalOpen,
  cozyPenguins,
  selected,
  setFee,
}: {
  region: string;
  loaded: boolean;
  setModalOpen: (open) => void;
  cozyPenguins: number[];
  selected: boolean[];
  setFee: (any) => void;
}) => {
  const canEnter = cozyPenguins.length > 0;
  const contracts = useContext(ContractsContext);
  const ccip = region === "cozyreef" ? contracts.ccipEth : contracts.ccipAvax;
  const img = canEnter
    ? "/assets/images/region/cozyreef/portal/portal.gif"
    : region === "polarpeaks"
    ? "/assets/images/region/polarpeaks/portal/portal_2.png"
    : "/assets/images/region/cozyreef/portal/portal.png";

  const text = canEnter ? (
    <>
      You can <Bold>select multiple</Bold> Cozy Penguins to travel!
    </>
  ) : region === "polarpeaks" ? (
    <>
      Cozy Penguins Explorers are setting up camp. <br /> <br /> Although, they
      can go back to the Cozy Reef if they want!
    </>
  ) : (
    <>
      The cozy lab team opened the portal! We don't know what's on the other
      side. Should we jump in?
    </>
  );
  return (
    <div className={styles.warpGate}>
      <img className={styles.warpGateImg} src={img} />
      <div className={styles.warpGateFloat}>
        <CssButton
          onClick={async (resolve, reject) => {
            setModalOpen(true);
            const selectedTokenIds = cozyPenguins
              .filter((_, i) => selected[i])
              .map((cozyPenguin) => cozyPenguin);

            const fee = await ccip.travelRequest(selectedTokenIds);
            if (!fee) {
              reject("unable to retrieve CCIP fee");
            }
            setFee(ethers.formatEther(fee as ethers.BigNumberish));
          }}
          className={classNames({
            [styles.hidden]: !canEnter,
          })}
          rounded={false}
          color={region === "cozyreef" ? "#e666d7" : "#42e6e8"}
          text="Enter"
          fontSize="2rem"
          shadowSize="4px"
          disabled={!loaded || selected.filter((e) => e).length === 0}
        />

        <TextSign
          img={
            <img
              className={classNames(styles.sign, {
                [styles.withoutEnterButton]: !canEnter,
              })}
              src="/assets/images/region/cozyreef/portal/speech_bubble.png"
            />
          }
          style={{
            bottom: "50%",
            transform: "translateY(65%)",
          }}
          className={classNames(styles.small, {
            [styles.withoutEnterButton]: !canEnter,
          })}
        >
          {text}
        </TextSign>
      </div>
    </div>
  );
};

export default WarpGate;
