import styles from "./index.module.scss";
import { useEffect, useRef } from "react";
import classNames from "classnames";
import { useImmer } from "use-immer";

export const GridCell = ({
  item,
  i,
  empty = false,
  selected,
  toggleSelected = () => {},
  loaded,
  notSelectable = false,
}: {
  item?: number;
  i: number;
  empty?: boolean;
  selected: boolean;
  toggleSelected?: () => void;
  loaded: boolean;
  notSelectable?: boolean;
}) => {
  const isSelectable = !empty && loaded;
  const randomColor = (i % 8) + 1;
  let selectedCapsuleImage;
  switch (randomColor) {
    case 1:
    case 8:
      selectedCapsuleImage = `url("/assets/images/region/polarpeaks/campsite/c_blue.png")`;
      break;
    case 2:
    case 5:
      selectedCapsuleImage = `url("/assets/images/region/polarpeaks/campsite/c_pink.png")`;
      break;
    case 3:
    case 6:
      selectedCapsuleImage = `url("/assets/images/region/polarpeaks/campsite/c_yellow.png")`;
      break;
    case 4:
    case 7:
      selectedCapsuleImage = `url("/assets/images/region/polarpeaks/campsite/c_white.png")`;
      break;
  }

  return (
    <div
      key={`cell-${i}`}
      className={classNames(styles.cell, {
        [styles.empty]: empty,
        [styles.selected]: selected,
        [styles.selectable]: isSelectable && !notSelectable,
        [styles.loading]: !loaded,
      })}
      onClick={() => {
        if (isSelectable && !empty) {
          toggleSelected();
        }
      }}
    >
      {loaded ? (
        <div
          className={styles.capsule}
          style={
            selected
              ? {
                  backgroundImage: selectedCapsuleImage,
                }
              : {}
          }
        >
          <div className={styles.capsuleId}>{item}</div>
        </div>
      ) : (
        <img
          alt="capsule"
          className={styles.capsule}
          style={{ height: "80px", width: "80px" }}
          src="/assets/images/icons/loading.gif"
        />
      )}
    </div>
  );
};

const CapsulesGrid = ({
  balance,
  capsules,
  selected,
  toggleSelected,
  loaded,
}: {
  balance?: number;
  capsules: number[];
  selected?: boolean[];
  toggleSelected?: (i) => void;
  loaded?: boolean;
}) => {
  const [state, setState] = useImmer<{
    numColumns: number;
  }>({
    numColumns: 0,
  });

  const gridRef = useRef(null);

  const getNumColumns = () => {
    const current = gridRef.current;
    const computedStyles = window.getComputedStyle(current);
    return computedStyles.getPropertyValue("grid-template-columns").split(" ")
      .length;
  };

  const onResize = () => {
    setState((state) => {
      state.numColumns = getNumColumns();
    });
  };

  useEffect(() => {
    onResize();
  }, [capsules]);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  const mod = capsules.length % state.numColumns;

  const numPaddedCells =
    capsules.length <= state.numColumns * state.numColumns
      ? state.numColumns * state.numColumns - capsules.length
      : mod > 0
      ? state.numColumns - mod
      : 0;

  const loadingBody = (
    <>
      {Array.from({ length: balance }).map((item, i) => (
        <GridCell
          i={i}
          loaded={false}
          selected={false}
          toggleSelected={() => {}}
        />
      ))}
    </>
  );

  const loadedBody = (
    <>
      {[...capsules]
        .sort((a, b) => a - b)
        .map((item, i) => (
          <GridCell
            item={item}
            i={i}
            loaded={loaded}
            selected={selected[i]}
            toggleSelected={() => toggleSelected(i)}
          />
        ))}
      {Array.from({ length: numPaddedCells }).map((_, i) => (
        <GridCell
          item={null}
          i={i + capsules.length}
          empty
          loaded={loaded}
          selected={false}
        />
      ))}
    </>
  );

  return (
    <div className={styles.selectWrapper}>
      <div className={styles.select}>
        <div className={styles.grid}>
          <div className={styles.gridContainer} ref={gridRef}>
            {loaded ? loadedBody : loadingBody}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CapsulesGrid;
